<template>
  <div :class="customerId != 0 ? 'custom custom-pad' : 'custom'">
    <div class="loading" v-if="loading">
      <a-spin />
    </div>
    <template v-else>
      <template v-if="customerId != 0">
        <a-collapse expandIconPosition="right" v-model="activeKey" :bordered="false">
          <a-collapse-panel key="1" header="基础信息">
            <a-space class="space">
              <div class="avatar" v-if="customerData.avatar">
                <img :src="customerData.avatar" />
              </div>
              <a-avatar icon="user" class="avatar" v-else />
              <div class="avatar-cont">
                <div class="name">
                  {{ customerData.name }}
                  <span class="txt" v-if="customerData.type == 1">@微信</span>
                  <span class="txt" v-else>@企业微信</span>
                </div>
                <div class="sex">
                  <template v-if="customerData.sex == 1">男</template>
                  <template v-else-if="customerData.sex == 2">女</template>
                </div>
              </div>
            </a-space>
            <div class="list">
              <div class="item">
                <span class="item-span">客户ID：</span>
                <span>{{ customerData.id }}</span>
                <!-- <a-icon type="edit" style="color: #13c2c2" @click="" /> -->
              </div>
              <div class="item">
                <span class="item-span">备注名：</span>
                <span>{{ customerData.name }}</span>
                <!-- <a-icon type="edit" style="color: #13c2c2" @click="" /> -->
              </div>
              <div class="item">
                <span class="item-span">电话：</span>
                <a target="_blank" :href="'tel:' + customerData.mobile">
                  {{ customerData.mobile }}
                </a>
                <!-- <a-icon type="edit" style="color: #13c2c2" /> -->
              </div>
              <div class="item">
                <span class="item-span">地区：</span>
                <span>
                  {{ customerData.province }}
                  <template v-if="customerData.city">-</template>
                  {{ customerData.city }}
                  <template v-if="customerData.district">-</template>
                  {{ customerData.district }}
                </span>
                <!-- <a-icon type="edit" style="color: #13c2c2" /> -->
              </div>
              <div class="item" v-if="customerData.addressInfo">
                <span class="item-span">收货地址：</span>
                {{
                  customerData.addressInfo.province +
                    customerData.addressInfo.city +
                    customerData.addressInfo.district
                }}
                <template v-if="!isEdit">
                  <span>{{ customerData.addressInfo.address }}</span>
                  <a-icon
                    type="edit"
                    style="color: #13c2c2; margin-left: 5px"
                    @click="editAdress"
                  />
                </template>
                <div class="address" v-else>
                  <textarea
                    v-model="form.address"
                    class="textarea"
                    placeholder="请输入详细地址"
                  ></textarea>
                  <div class="btn-box">
                    <a-button class="btn" size="small" type="primary" @click="saveAdress">
                      保存
                    </a-button>
                    <a-button class="btn" size="small" @click="isEdit = false">取消</a-button>
                  </div>
                </div>
              </div>
              <div class="item">
                <span class="item-span">企业：</span>
                <span v-if="customerData.remark_corp_name">
                  {{ customerData.remark_corp_name }}
                </span>
                <span v-else-if="customerData.corp_full_name">
                  {{ customerData.corp_full_name }}
                </span>
                <span v-else>{{ customerData.corp_name }}</span>
                <!-- <a-icon type="edit" style="color: #13c2c2" /> -->
              </div>
              <div class="item">
                <span class="item-span">描述：</span>
                <span>{{ customerData.description }}</span>
                <!-- <a-icon type="edit" style="color: #13c2c2" /> -->
              </div>
              <div class="item">
                <span class="item-span">添加时间：</span>
                <span class="default-color time">
                  {{ customerData.insert_time | dateFormat('Y-m-d') }}
                </span>
              </div>
              <div class="item">
                <span class="item-span">最后聊天时间：</span>
                <span class="default-color time">
                  {{ customerData.last_follow_time | dateFormat('Y-m-d') }}
                </span>
              </div>
            </div>
          </a-collapse-panel>
          <a-collapse-panel key="2" header="添加员工">
            <div class="item">
              <a-tag
                v-for="(item, index) in customerData.addUser"
                :key="index"
                style="margin-bottom: 5px;"
                v-if="customerData.addUser != ''"
              >
                {{ item }}
              </a-tag>
            </div>
          </a-collapse-panel>
          <a-collapse-panel key="3" header="所在群聊">
            <div class="item">
              <a-tag
                v-for="(item, index) in customerData.chat"
                :key="index"
                style="margin-bottom: 5px;"
                v-if="customerData.chat != ''"
              >
                {{ item }}
              </a-tag>
            </div>
          </a-collapse-panel>
          <a-collapse-panel key="4" header="客户标签">
            <div class="item">
              <a-tag
                v-for="(item, index) in customerData.tags"
                :key="index"
                style="margin-bottom: 5px;"
                v-if="customerData.tags != ''"
              >
                {{ item.name }}
              </a-tag>
            </div>
          </a-collapse-panel>
          <a-collapse-panel key="5" header="客户价值">
            <div class="antd-pro-cardList">
              <a-card :bordered="false" class="antd-pro-card-box">
                <h3 class="antd-pro-card-title">
                  <span>客户价值分</span>
                  <a-tooltip placement="top" class="antd-pro-tipicon">
                    <template slot="title">
                      客户价值分为活跃度得分，添加时间得分和跟进时间得分的总和。分数大于70分的为高价值客户，40到70分之间的为一般价值客户，低于40分的为潜在客户
                    </template>
                    <a-icon type="exclamation-circle" />
                  </a-tooltip>
                </h3>
                <div class="antd-pro-score">
                  <span class="antd-pro-score-num">{{ customerPoint }}</span>
                  分 /
                  <span class="antd-pro-score-average">平均{{ customerAvgPoint }}分</span>
                </div>
                <div class="antd-pro-remark">潜在客户</div>
                <div class="antd-pro-percent" v-if="customerPoint >= customerAvgPoint">
                  <a-icon type="caret-up" />
                  高于平均分{{ (customerPoint - customerAvgPoint).toFixed(2) }}%
                </div>
                <div class="antd-pro-percent-down" v-else>
                  <a-icon type="caret-down" />
                  低于平均分{{ (customerAvgPoint - customerPoint).toFixed(2) }}%
                </div>
              </a-card>
              <a-card :bordered="false" class="antd-pro-card-box">
                <h3 class="antd-pro-card-title">
                  <span>活跃得分</span>
                  <a-tooltip placement="top" class="antd-pro-tipicon">
                    <template slot="title">
                      活跃时间越近的客户活跃度得分越高（当日添加的客户不统计）
                    </template>
                    <a-icon type="exclamation-circle" />
                  </a-tooltip>
                </h3>
                <div class="antd-pro-score">
                  <span class="antd-pro-score-num">0</span>
                  分 /
                  <span class="antd-pro-score-average">平均0分</span>
                </div>
                <div class="antd-pro-remark">客户连续50天以上没有活跃, 扣除50分&nbsp;</div>
                <div class="antd-pro-percent">
                  <a-icon type="caret-up" />
                  高于平均分0%
                </div>
              </a-card>
              <a-card :bordered="false" class="antd-pro-card-box">
                <h3 class="antd-pro-card-title">
                  <span>添加时间得分</span>
                  <a-tooltip placement="top" class="antd-pro-tipicon">
                    <template slot="title">
                      添加时间越远的客户活跃度得分越高（当日添加的客户不统计）
                    </template>
                    <a-icon type="exclamation-circle" />
                  </a-tooltip>
                </h3>
                <div class="antd-pro-score">
                  <span class="antd-pro-score-num">{{ customerData.addPoint }}</span>
                  分 /
                  <span class="antd-pro-score-average">平均{{ customerData.avgAddPoint }}分</span>
                </div>
                <div class="antd-pro-remark">客户添加时间为{{ day }}天</div>
                <div
                  class="antd-pro-percent"
                  v-if="customerData.addPoint >= customerData.avgAddPoint"
                >
                  <a-icon type="caret-up" />
                  高于平均分{{ (customerData.addPoint - customerData.avgAddPoint).toFixed(2) }}%
                </div>
                <div class="antd-pro-percent-down" v-else>
                  <a-icon type="caret-down" />
                  低于平均分{{ (customerData.avgAddPoint - customerData.addPoint).toFixed(2) }}%
                </div>
              </a-card>
              <a-card :bordered="false" class="antd-pro-card-box">
                <h3 class="antd-pro-card-title">
                  <span>跟进得分</span>
                  <a-tooltip placement="top" class="antd-pro-tipicon">
                    <template slot="title">
                      跟进时间越近的客户跟进时间得分越高（当日添加的客户不统计）
                    </template>
                    <a-icon type="exclamation-circle" />
                  </a-tooltip>
                </h3>
                <div class="antd-pro-score">
                  <span class="antd-pro-score-num">{{ customerData.followPoint }}</span>
                  分 /
                  <span class="antd-pro-score-average">
                    平均{{ customerData.avgfollowPoint }}分
                  </span>
                </div>
                <div class="antd-pro-remark">客户连续25天以上没有跟进记录, 扣除25分</div>
                <div
                  class="antd-pro-percent"
                  v-if="customerData.followPoint >= customerData.avgfollowPoint"
                >
                  <a-icon type="caret-up" />
                  高于平均分{{
                    (customerData.followPoint - customerData.avgfollowPoint).toFixed(2)
                  }}%
                </div>
                <div class="antd-pro-percent-down" v-else>
                  <a-icon type="caret-down" />
                  低于平均分{{
                    (customerData.avgfollowPoint - customerData.followPoint).toFixed(2)
                  }}%
                </div>
              </a-card>
            </div>
          </a-collapse-panel>
          <a-collapse-panel key="6" header="跟进记录">
            <!-- 跟进记录 start -->
            <div class="step">
              <div class="step-list">
                <template v-for="(item, index) in customerData.followInfo">
                  <div class="item item1" v-if="item.type == 2" :key="index">
                    <div class="title">{{ item.message }}</div>
                    <div class="desc">
                      <span class="time">{{ item.insert_time | dateFormat('Y-m-d') }}</span>
                    </div>
                  </div>
                  <div class="item item2" v-else-if="item.type == 1" :key="index">
                    <div class="title">{{ item.message }}</div>
                    <div class="desc">
                      <span class="time">{{ item.insert_time | dateFormat('Y-m-d') }}</span>
                    </div>
                  </div>
                  <div class="item item3" v-else-if="item.type == 3" :key="index">
                    <div class="title">{{ item.message }}</div>
                    <div class="desc">
                      <span class="time">{{ item.insert_time | dateFormat('Y-m-d') }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <!-- 跟进记录 end -->
          </a-collapse-panel>
        </a-collapse>
        <!-- 回复 start -->
        <div class="message" v-if="!isH5">
          <div class="message-cont">
            <a-textarea v-model="messageValue" placeholder="添加今日跟进记录" />
            <div class="btn-cont">
              <a-button type="primary" @click="okFunc">确定</a-button>
            </div>
          </div>
        </div>
        <!-- 回复 end -->
        <!-- 联系客户 start -->
        <div class="contact" v-else>
          <a-button type="primary" class="btn" @click="() => sendContact()">
            查看客户
          </a-button>
        </div>
        <!-- 联系客户 end -->
      </template>
      <div class="custom-chat" v-else>
        <template v-if="groupInfo != ''">
          <div class="custom-chat-cont">
            <a-space>
              <div class="avatar no-avatar">
                <a-icon type="team" />
              </div>
              <div class="avatar-cont">
                <div class="name">
                  {{ groupInfo.group_info.name }}
                </div>
                <div class="time">
                  创建时间：{{ groupInfo.group_info.insert_time | dateFormat('Y-m-d H:i:s') }}
                </div>
                <div class="sex">
                  <span>成员性别：</span>
                  <span class="sex-item">
                    <a-icon type="man" />
                    <span>{{ groupInfo.group_gender.gender_male }}人</span>
                  </span>
                  <span class="sex-item">
                    <a-icon type="woman" />
                    <span>{{ groupInfo.group_gender.gender_female }}人</span>
                  </span>
                  <span class="sex-item">
                    <a-icon type="question" />
                    <span>{{ groupInfo.group_gender.gender_unknown }}人</span>
                  </span>
                </div>
              </div>
            </a-space>
            <a-row class="custom-chat-info">
              <a-col :span="8">
                <div class="num">{{ groupInfo.group_info.num }}</div>
                <div class="info">全部群成员</div>
              </a-col>
              <a-col :span="8">
                <div class="num">{{ groupInfo.group_info.addMemberNum }}</div>
                <div class="info">今日入群</div>
              </a-col>
              <a-col :span="8">
                <div class="num">{{ groupInfo.group_info.leaveMemberNum }}</div>
                <div class="info">今日退群</div>
              </a-col>
            </a-row>
          </div>
          <div class="custom-chat-list">
            <div class="tit">群内成员</div>
            <div class="list" v-if="groupList.length > 0">
              <a-space class="list-item" v-for="(item, index) in groupList" :key="index">
                <div class="avatar" v-if="item.avatar">
                  <img :src="item.avatar" />
                </div>
                <a-avatar icon="user" size="large" class="avatar" v-else />
                <div class="list-cont">
                  <div class="name">
                    <span>
                      <span v-if="item.group_nickname">{{ item.group_nickname }}</span>
                      <span v-else>{{ item.name }}</span>
                      <span class="txt" v-if="item.type == 2">@微信</span>
                    </span>
                    <span class="is-owner" v-if="item.is_owner">群主</span>
                  </div>
                  <div class="info">
                    <span class="staff-time">{{ item.insert_time | dateFormat('Y-m-d') }}</span>
                    <template v-if="item.invitor">
                      由员工
                      <span class="staff-name">{{ item.invitor }}</span>
                    </template>
                    <span v-else>由群成员</span>
                    {{ item.insert_scene }}
                  </div>
                </div>
              </a-space>
              <load-more :status="loadStatus"></load-more>
            </div>
          </div>
        </template>
        <div v-else>
          <a-empty :image="simpleImage" description="你已不在此群" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue';
import { getAction, postAction } from '@/api/manage';
import { dateFormat } from '@/utils/util';
import loadMore from '@/components/loadMore.vue';
import { ListMixin } from '@/mixins/ListMixin';
export default {
  name: 'index',
  components: {
    loadMore,
  },
  mixins: [ListMixin],
  data() {
    return {
      loading: true,
      isSend: false, // 防止重复获取
      messageValue: '',
      url: {
        details: 'scrm/customer/view',
        customerUrl: 'scrm/customer-user-follow/create',
        address: 'scrm/customer/edit-address',
        groupUrl: 'scrm/work-state/get-group-info',
      },
      customerData: {}, //客户数据
      customerPoint: 0, //总分
      customerAvgPoint: 0, //平均分
      day: 0,
      isEdit: false,
      form: {
        address_id: '',
        address: '',
      },
      customerId: 0, //客户id
      groupInfo: '', //群数据
      loadStatus: 'loading',
      queryParam: {
        group_id: '',
        sort: '-insert_time',
      },
      groupList: [],
      activeKey: ['1', '2', '3', '4', '5', '6'],
      isH5: false, //是否h5页面打开
      userid: '', //
    };
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  created() {
    this.customerId = this.$store.state.auth.customer_id;
    // 判断h5打开
    if (this.$route.query.customer_id && this.customerId == 0) {
      this.customerId = this.$route.query.customer_id;
      this.isH5 = true;
    }
    this.queryParam.group_id = this.$store.state.auth.group_id;
    if (this.customerId != 0) {
      document.title = '客户资料';
      this.getDetails();
    } else {
      this.getGroup();
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  methods: {
    getDetails() {
      getAction(this.url.details, { id: this.customerId }).then((res) => {
        this.customerData = res;
        this.userid = this.customerData.external_userid;
        if (this.customerData.addUser != '') {
          this.customerData.addUser = this.customerData.addUser.split(',');
        }
        if (this.customerData.chat != '') {
          this.customerData.chat = this.customerData.chat.split(',');
        }
        this.customerPoint = this.customerData.addPoint + this.customerData.followPoint;
        this.customerAvgPoint = (
          this.customerData.avgAddPoint + this.customerData.avgfollowPoint
        ).toFixed(2);
        this.loading = false;
        let time = dateFormat(this.customerData.insert_time * 1000, 'Y-m-d');
        this.day = this.dateDiff(time);
      });
    },
    getGroup() {
      if (this.queryParam.group_id != '') {
        if (this.isSend) {
          return;
        }
        var params = this.getQueryParams(); //查询条件
        this.isSend = true;
        getAction(this.url.groupUrl, params)
          .then((res) => {
            if (res.data) {
              if (this.ipagination.current == 1) {
                this.groupInfo = res.data;
                this.totalPage = res.totalPage;
              }
              this.groupList = this.groupList.concat(res.data.member_detail);
              if (this.totalPage > this.ipagination.current) {
                this.loadStatus = 'loading';
                this.ipagination.current++;
              } else {
                this.loadStatus = 'noMore';
              }
            }
          })
          .finally(() => {
            this.loading = false;
            this.isSend = false;
          });
      } else {
        this.loading = false;
      }
    },
    // 加载更多
    onScroll(e) {
      if (this.loadStatus == 'noMore') {
        return;
      }
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight == scrollHeight) {
        this.getGroup();
      }
    },
    okFunc() {
      let _this = this;
      if (this.messageValue) {
        this.$confirm({
          title: '确定提交记录?',
          okText: '确认',
          cancelText: '取消',
          onOk() {
            postAction(_this.url.customerUrl, {
              customer_id: _this.customerData.id,
              content: _this.messageValue,
            }).then(() => {
              _this.$message.success('成功');
              _this.customerData.followInfo.unshift({
                message: '[跟进] 我：' + _this.messageValue,
                type: 1,
                insert_time: new Date().getTime() / 1000,
              });
              _this.messageValue = '';
            });
          },
        });
      }
    },
    //天数
    dateDiff(sDate1) {
      var date2 = new Date();
      var date1 = new Date(Date.parse(sDate1.replace(/-/g, '/')));
      var iDays = parseInt(Math.abs(date2.getTime() - date1.getTime()) / 1000 / 60 / 60 / 24);
      return iDays;
    },
    // 修改地址
    editAdress() {
      this.isEdit = true;
      this.form = {
        address_id: this.customerData.addressInfo.id,
        address: this.customerData.addressInfo.address,
      };
    },
    saveAdress() {
      if (this.isSend) {
        return;
      }
      this.isSend = true;
      postAction(this.url.address, this.form)
        .then(() => {
          this.isEdit = false;
          this.$message.success('修改成功');
          this.getDetails();
        })
        .finally(() => {
          this.isSend = false;
        });
    },

    // 联系客服
    sendContact() {
      this.$wx.invoke(
        'openUserProfile',
        {
          type: 2, //1表示该userid是企业成员，2表示该userid是外部联系人
          userid: this.userid, //可以是企业成员，也可以是外部联系人
        },
        function(res) {
          console.log(res);
          if (res.err_msg != 'openUserProfile:ok') {
            //错误处理
          }
        }
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
@import '../../style/less/func';
.loading {
  padding: 20px 0;
  text-align: center;
}
.default-color {
  color: #13c2c2;
}
.custom {
  .space {
    padding-bottom: 20px;
  }
  &-pad {
    padding-bottom: 300px;
  }
  .ant-collapse-borderless {
    background: #fff;
  }
  .ant-collapse-borderless > .ant-collapse-item {
    border: 0;
    border-bottom: 12px solid #eee;
  }
  .avatar {
    width: 100px;
    height: 100px;
    line-height: 100px;
    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    .anticon {
      font-size: 40px;
    }
  }
  .avatar-cont {
    line-height: 40px;
    vertical-align: middle;
    .name {
      font-size: 36px;
      color: #333;
      .txt {
        font-size: 24px;
        color: #60b66f;
      }
    }
    .sex {
      padding-top: 10px;
      font-size: 28px;
      color: #999;
      span {
        vertical-align: middle;
      }
    }
  }

  .list {
    font-size: 30px;
    line-height: 60px;
    .ant-tag {
      font-size: 30px;
      line-height: 40px;
    }
    .time {
      font-size: 36px;
    }
  }
  // .more {
  //     color: #13C2C2;
  //     font-size: 24px;
  //     text-decoration: underline;
  // }
  .antd-pro-cardList {
    padding-top: 10px;
    font-size: 0;
    .antd-pro-card-box {
      display: inline-block;
      width: 48%;
      margin-bottom: 4%;
      border: 1px solid #13c2c2;
      background: #fff;
      border-radius: 5px;
      color: #13c2c2;
      &:nth-child(odd) {
        margin-right: 2%;
      }
      &:nth-child(even) {
        margin-left: 2%;
      }
      .antd-pro-tipicon {
        color: #13c2c2;
      }
      .ant-card-body {
        padding: 24px;
      }
      .antd-pro-card-title {
        color: #999;
        font-size: 28px;
        margin: 0;
      }
      .antd-pro-tipicon {
        padding: 0 5px;
      }
      .antd-pro-score {
        padding: 10px 0;
        font-size: 24px;
        color: #13c2c2;
        .antd-pro-score-average {
          color: #999;
        }
      }
      .antd-pro-score-num {
        font-size: 48px;
        font-weight: 500;
      }
      .antd-pro-remark {
        font-size: 24px;
        color: #13c2c2;
        .ellipsis(1);
        height: 50px;
        line-height: 50px;
        margin-bottom: 10px;
      }
      .antd-pro-percent {
        background: linear-gradient(270deg, rgba(19, 194, 194, 0.48), #13c2c2);
        color: #fff;
        padding: 5px 10px;
        border-radius: 3px;
        width: 260px;
        font-size: 26px;
        &-down {
          color: #fff;
          padding: 2px 5px;
          border-radius: 3px;
          width: 260px;
          font-size: 26px;
          background: linear-gradient(270deg, rgba(189, 186, 186, 0.48), #bdbaba);
        }
      }
    }
  }
  .step {
    padding: 0 20px 10px;
    &-list {
      font-size: 24px;
      line-height: 40px;
      .item {
        padding: 10px 0 10px 20px;
        margin-bottom: 20px;
        border-left: 10px solid #13c2c2;
        background: rgba(230, 255, 251, 1);
        .title {
          font-weight: 700;
        }
        .desc {
          padding-left: 8px;
          font-size: 24px;
          color: #999;
        }
        &1 {
          color: #13c2c2;
          border-left: 10px solid #13c2c2;
          background: rgba(230, 255, 251, 1);
        }
        &2 {
          color: #1890ff;
          border-left: 10px solid #1890ff;
          background: #f4faff;
        }
        &3 {
          color: #fc780e;
          border-left: 10px solid #fc780e;
          background: #fff9f3;
        }
      }
    }
  }
  .message {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background: #fff;
    &-cont {
      padding: 20px;
      .ant-input {
        height: 150px;
      }
      .btn-cont {
        padding-top: 20px;
        text-align: right;
        font-size: 24px;
      }
    }
  }

  .contact {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 160px;
    background: #fff;
    text-align: center;
    border-top: 2px solid #eee;
    .btn {
      width: 90%;
      height: 100px;
      margin-top: 30px;
      line-height: 100px;
      font-size: 36px;
      border-radius: 50px;
    }
  }
}

.address {
  display: flex;
  flex-direction: column;
  textarea {
    line-height: 50px;
    height: 120px;
  }
  .btn-box {
    margin-top: 20px;
    .btn {
      margin-right: 20px;
    }
  }
}
.custom-chat {
  &-cont {
    padding: 20px;
  }
  .no-avatar {
    background: #1472ff;
    text-align: center;
    .anticon-team {
      text-align: center;
      vertical-align: middle;
      color: #fff;
      font-size: 50px;
    }
  }
  .time {
    font-size: 28px;
    color: #999;
  }
  .avatar-cont {
    .name {
      font-size: 28px;
      font-weight: bold;
    }
    .sex {
      padding-top: 0;
      &-item {
        padding-right: 20px;
        line-height: 30px;
        vertical-align: middle;
        span {
          vertical-align: middle;
        }
      }
      .anticon {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 20px;
        color: #fff;
        margin-right: 5px;
        svg {
          margin-top: 5px;
        }
      }
      .anticon-man {
        background: #33c6fc;
      }
      .anticon-woman {
        background: #f97489;
      }
      .anticon-question {
        background: #b2b2b2;
      }
    }
  }
  &-info {
    border-top: 1px solid #ececec;
    margin-top: 30px;
    padding: 20px 0;
    .num {
      font-size: 48px;
      font-weight: bold;
      text-align: center;
    }
    .info {
      text-align: center;
      font-size: 28px;
    }
  }
  &-list {
    border-top: 20px solid #f7f8fa;
    .tit {
      padding-left: 20px;
      line-height: 80px;
      border-bottom: 1px solid #ececec;
      font-size: 28px;
    }
    .list {
      margin: 0 20px;
      .list-item {
        width: 100%;
        padding: 20px 0;
        border-bottom: 1px solid #ececec;
      }
      .avatar {
        width: 90px;
        height: 90px;
        line-height: 90px;
        img {
          width: 90px;
          height: 90px;
          border-radius: 50%;
        }
        .anticon {
          font-size: 40px;
        }
      }
      .list-cont {
        width: 600px;
        line-height: 40px;
        .name {
          color: #333;
          display: flex;
          justify-content: space-between;
          .txt {
            color: #60b66f;
          }
          .is-owner {
            padding: 0 10px;
            border: 1px solid #1472ff;
            color: #1472ff;
            border-radius: 10px;
          }
        }
        .info {
          color: #999;
          font-size: 28px;
          .staff-time {
            padding-right: 10px;
          }
          .staff-name {
            padding: 0 10px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 600px) {
  .custom .contact {
    height: 1rem;
    .btn {
      height: 0.8rem;
      margin-top: 0.1rem;
      line-height: 0.8rem;
      border-radius: 1rem;
      font-size: 30px;
    }
  }
  .custom-pad {
    padding-bottom: 1rem;
  }
}
</style>
