<template>
    <a-spin :spinning="loading">
        <div class="content">
            <a-row :gutter="16" class="static" style="text-align: center; padding-bottom: 10px" v-if="static">
                <a-col :span="8">
                    <a-statistic title="消费总金额" :value="static.money || 0" :value-style="{ color: '#ff4d4f' }"></a-statistic>
                </a-col>
                <a-col :span="8">
                    <a-statistic title="消费总单数" :value="static.total || 0" :value-style="{ color: '#ff4d4f' }"></a-statistic>
                </a-col>
                <a-col :span="8">
                    <a-statistic title="平均单价" :value="static.per || 0" :value-style="{ color: '#ff4d4f' }"></a-statistic>
                </a-col>
            </a-row>
            <div v-if="dataSource.length" class="goods-list" @scroll="onScroll">
                <ul>
                    <li v-for="(item, index) in dataSource" :key="index">
                        <div class="img-box">
                            <img :src="item.goods_pic">
                            <div class="text">
                                <div class="title">{{item.goods_name}}</div>
                                <div class="price"><span>￥</span>{{item.money}}</div>
                            </div>
                        </div>
                        <div class="other">
                            <span class="times">{{timeFormat(item.pay_time)}}</span>
                            <a-button type="primary" @click="sendGoods(item)">发送</a-button>
                        </div>
                    </li>
                </ul>
                <load-more :status="loadStatus"></load-more>
            </div>
            <div v-else>
                <a-empty :image="simpleImage" description="暂无商品" />
            </div>
        </div>
    </a-spin>
</template>

<script>
    import { Empty } from 'ant-design-vue';
    import { getAction } from '@/api/manage'
    import { ListMixin } from '@/mixins/ListMixin'
    import { dateFormat } from '@/utils/util'
    import loadMore from '@/components/loadMore.vue'
    export default {
        name: 'RecordListModal',
        components: {
            loadMore,
        },
        props:['title'],
        mixins: [ListMixin],
        beforeCreate() {
            this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        },
        data() {
            return {
                loading: true,
                url: {
                    list: 'scrm/work-state/expenses',
                    static: 'scrm/work-state/order-count'
                },
                queryParam: {
                    title: '',
                    customer_id: ''
                },
                isSend: false, // 防止重复获取
                loadStatus: 'loading',
                static: {}, // 统计数据
            }
        },
        created() {
            this.getDetails();
            this.getStatic();
        },
        mounted() {
        },
        methods: {

            // 处理时间
            timeFormat(times) {
                return dateFormat(times * 1000, 'Y-m-d H:i')
            },

            sendGoods(item) {
                let obj = {
                    link: item.goods_url, //H5消息页面url 必填
                    title: item.goods_name, //H5消息标题
                    desc: "", //H5消息摘要
                    imgUrl: item.goods_pic, //H5消息封面图片URL
                }
                this.sendCard(item.id, 2, obj);
            },

            getDetails() {
                if(this.isSend) {return}
                var params = this.getQueryParams(); //查询条件
                if(this.ipagination.current == 1) {
                    this.loading = true;
                    this.dataSource = [];
                }
                this.isSend = true;
                this.loadStatus = 'loading'
                getAction(this.url.list, params).then(res => {
                    if (res && res.length >= 0 ) {
                        this.dataSource = this.dataSource.concat(res);
                        if (res.length < this.ipagination.pageSize) {
                            this.loadStatus = 'noMore'
                        }
                        else {
                            this.loadStatus = 'loading'
                            this.ipagination.current++
                        }
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.isSend = false;
                });
            },
            getStatic() {
                var params = this.getQueryParams(); //查询条件
                console.log('customer_id')
                getAction(this.url.static, params).then(res => {
                    this.static = res
                })
            },

            onScroll(e) {
                if(this.loadStatus == 'noMore') {
                    return
                }
                if(e.target.scrollTop + e.target.clientHeight + 400 >= e.target.scrollHeight) {
                    this.getDetails()
                }
            }
        },
        watch: {
            title() {
                this.queryParam.title = this.title;
                this.ipagination.current = 1
                this.getDetails();
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
    // @import '@/style/less/func';
    .default-color {
        color: #13C2C2;
    }
    .content {
        .ant-collapse-borderless {
            background: #fff;
        }
        .ant-collapse-borderless>.ant-collapse-item {
            border: 0;
            > .ant-collapse-header {
                padding: 12px 16px 12px 80px;
            }
        }
        .ant-collapse {
            > .ant-collapse-item ｛
                > .ant-collapse-header {
                    padding: 6px 16px;
                }

        }
    }
</style>
