<template>
  <div class="box-content">
    <div class="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
export default {
  name: 'message',
  data() {
    return {
      external_userid: '',
      entry: ''
    };
  },
  created() {
    console.log(this.$route.query.goindex, 'this.$route.query.goindex');
    this.external_userid = this.$route.query.external_userid;
    // this.$wx.onHistoryBack(() => {
    //   return confirm('确定要放弃当前页面的修改？')
    // });
    // setTimeout(() => {
    //   requestAnimationFrame(() => wx.closeWindow())
    // }, 1000)
    this.sendContact(this.external_userid);
  },
  activated() {
    this.setgoindex()
  },
  methods: {
    // 联系客户
    sendContact() {
      // console.log(external_userid, 'external_userid');
      let _this = this;
      this.$wx.openEnterpriseChat({
        // 注意：userIds和externalUserIds至少选填一个。内部群最多2000人；外部群最多500人；如果有微信联系人，最多40人
        userIds: '', //参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
        externalUserIds: this.external_userid, // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
        groupName: '', // 会话名称。单聊时该参数传入空字符串""即可。
        chatId: '', // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
        success() {
          // _this.$wx.closeWindow();

        },
        fail: function(res) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        },
      });
    },
    setgoindex() {
      if (window.history.length <= 1) {
        if (location.href.indexOf('?') === -1) {
          window.location.href = location.href + '?goindex=true'
        } else if (location.href.indexOf('?') !== -1 && location.href.indexOf('goindex') === -1) {
          window.location.href = location.href + '&goindex=true'
        }
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
@import '../../style/less/func';
.loading {
  padding: 20px 0;
  text-align: center;
}
</style>
