/**
 * 新增修改完成调用 modalFormOk方法 编辑弹框组件ref定义为modalForm
 * 高级查询按钮调用 superQuery方法  高级查询组件ref定义为superQueryModal
 * data中url定义 list为查询列表  delete为删除单条记录  deleteBatch为批量删除
 */
import { deleteAction, getAction, postAction } from '@/api/manage';
import { copyText } from '@/utils/util';

export const ListMixin = {
  data() {
    return {
      /* table加载状态 */
      loading: false,
      /* 查询条件-请不要在queryParam中声明非字符串值的属性 */
      queryParam: {},
      /* 数据源 */
      dataSource: [],
      /* 分页参数 */
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
        showTotal: (total) => `共 ${total} 条`
      },
      url: {
        card: 'scrm/work-state/send-click-record',
        sendMaterialUrl: 'scrm/work-state/replace-talk',
        concatUrl: 'scrm/customer/chat'
      }
    };
  },
  created() {
    this.queryParam.customer_id = this.$store.state.auth.customer_id;
  },
  methods: {
    // 请求数据
    loadData(arg) {
      if (!this.url.list) {
        this.$message.error('请设置url.list属性!');
        return;
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1;
      }
      var params = this.getQueryParams(); //查询条件
      this.loading = true;
      getAction(this.url.list, params).then((res) => {
        // 有分页
        if (res && res.data) {
          this.dataSource = res.data;
          this.ipagination.total = res.totalCount;
        }
        // 无分页
        else {
          this.dataSource = res;
        }
        this.loading = false;
      });
    },
    // 合并获取参数
    getQueryParams() {
      //获取查询条件
      let sqp = {};
      if (this.superQueryParams) {
        sqp['superQueryParams'] = encodeURI(this.superQueryParams);
      }
      // console.log(this.queryParam, 'jeecg-query')
      var param = Object.assign(sqp, this.queryParam);

      param.page = this.ipagination.current;
      param['per-page'] = this.ipagination.pageSize;
      return param;
    },

    // 通用删除
    handleDelete: function(id) {
      if (!this.url.delete) {
        this.$message.error('请设置url.delete属性!');
        return;
      }
      let that = this;
      deleteAction(that.url.delete, { id: id }).then(() => {
        that.$message.success('删除成功');
        that.loadData();
      });
    },

    // table翻页调整
    tableChange(pagination) {
      this.ipagination = pagination;
      this.loadData();
    },
    // 翻页回调
    onShowSizeChange(current, pageSize) {
      this.ipagination.pageSize = pageSize;
      this.ipagination.current = current;
      this.loadData();
    },

    // 发送课程
    sendCourse(item, chapter) {
      let courseDetail = '';
      let url = '';
      if (window.location.host.indexOf('tinternal.kllrkt.com') > -1) {
        url = 'http://dx.kuailelaorendaxue.test/#/';
      } else {
        url = 'https://dx.kuailelaorendaxue.com/#/';
      }
      // 章节
      if (chapter === 'chapter') {
        switch (+item.ctype) {
          // 课程
          case 1:
            courseDetail = 'cDetails';
            break;
          // 群课
          case 2:
            courseDetail = 'groupCoursePlay';
            break;
          // 直播课
          case 3:
            courseDetail = 'livevideo';
            break;
        }
      } else {
        switch (+item.ctype) {
          // 课程
          case 1:
            courseDetail = 'courseDetails';
            break;
          // 群课
          case 2:
            courseDetail = 'groupCourse';
            break;
          // 直播课
          case 3:
            courseDetail = 'liveDetails';
            break;
        }
      }
      let link = url + courseDetail + '?guid=' + item.guid;

      let obj = {
        link: link,
        title: item.name,
        imgUrl: item.cover
      };
      this.sendCard(item.id, 3, obj);
      console.log(item, obj, 'item');
    },

    // 发送卡片请求
    sendCard(id, send_type, obj) {
      if (!obj || Object.keys(obj).length === 0) {
        return;
      }
      let data = {
        id: id,
        send_type: send_type, // 1 话术库 2 商品 3 课程 4章节 5海报
        customer_id: this.queryParam.customer_id,
        link: obj.link,
        title: obj.title
      };
      if (this.queryParam.customer_id != 0) {
        postAction(this.url.card, data).then((res) => {
          if (send_type != 5) {
            let baseUrl = '';
            if (window.location.host.indexOf('tinternal.kllrkt.com') > -1) {
              baseUrl = 'http://tscrm.kllrkt.com/xapi/redirect/index?redirect_id=';
            } else {
              baseUrl = 'http://scrm.kllrkt.com/xapi/redirect/index?redirect_id=';
            }
            obj.link = baseUrl + res + '&redirect_url=' + encodeURIComponent(obj.link);
          }
          this.doSendNews(obj);
        });
      } else {
        this.doSendNews(obj);
      }
    },

    doSendNews(obj) {
      // 若是在企业微信内
      const isWork = navigator.userAgent.toLowerCase().includes('wxwork');
      if (isWork) {
        // 企业微信发送消息
        this.$wx.invoke('sendChatMessage', {
          msgtype: 'news',
          news: {
            link: obj.link || '', //H5消息页面url 必填
            title: obj.title || '', //H5消息标题
            desc: obj.desc || '', //H5消息摘要
            imgUrl: obj.imgUrl || '' //H5消息封面图片URL
          }
        });
      } else {
        // 企云宝
        const msg = {
          msg_type: 'link',
          content: {
            url: obj.link || '',
            title: obj.title || '',
            cover: obj.imgUrl || '',
            desc: obj.desc || '',
          }
        };
        window.parent.postMessage(JSON.stringify(msg), 'https://qyb.wxb.com')
      }
    },

    // 发送话术库
    sendMaterial(item) {
      let obj = {
        msgtype: item.type
      };
      if (item.type === 'news') {
        let cont = item.content;
        if (typeof cont == 'string') {
          cont = JSON.parse(cont);
        } else {
          cont = item.content;
        }
        obj = {
          link: cont.url, //H5消息页面url 必填
          title: cont.title, //H5消息标题
          desc: cont.description, //H5消息摘要
          imgUrl: cont.picurl //H5消息封面图片URL
        };
        this.sendCard(item.id, 1, obj);
        return;
      } else if (item.type == 'text') {
        let data;
        if (this.queryParam.customer_id != 0) {
          data = {
            talk_id: item.id || item.talk_id,
            customer_id: this.queryParam.customer_id
          };
        } else {
          data = {
            talk_id: item.id || item.talk_id,
            is_group: 1 //是否为群
          };
        }
        getAction(this.url.sendMaterialUrl, data).then((res) => {
          if (res) {
            obj[item.type] = {
              content: res //文本内容
            };
            this.$wx.invoke('sendChatMessage', obj);
            this.sendCard(item.id, 1);
          }
        });
        return;
      } else if (item.type == 'miniprogram') {
        let cont = item.content;
        if (typeof cont == 'string') {
          cont = JSON.parse(cont);
        }
        obj = {
          appid: cont.appid, //小程序的appid，企业已关联的任一个小程序
          title: cont.title, //小程序消息的title
          imgUrl: cont.picurl, //小程序消息的封面图。必须带http或者https协议头
          page: cont.pagepath //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
        };
        this.$wx.invoke('sendChatMessage', {
          msgtype: 'miniprogram', //消息类型，必填文本(“text”)，图片(“image”)，视频(“video”)，文件(“file”)，H5(“news”）和小程序(“miniprogram”)
          miniprogram: obj
        });
        return;
      } else {
        obj[item.type] = {
          mediaid: item.media_id || '3oIlSU3regO-KACEb1DKa0u1w8cjwoy6k4JHDnFxLkjU'
        };
      }
      this.$wx.invoke('sendChatMessage', obj);
      this.sendCard(item.id, 1);
    },

    // 联系客户
    concat(item, copy) {
      if (copy) {
        if (this.isJsonString(item.display)) {
          // 话术
          let obj = JSON.parse(item.display);
          if (obj.type == 'text') {
            this.copy(obj.data);
          } else {
            this.$message.error('暂不支持此类型复制');
          }
        } else {
          // 自建提醒
          this.copy(item.display);
        }
      }
      let obj = {
        talk_id: item.id,
        customer_id: item.customerid,
        external_userid: item.external_userid
      };

      if (item.customerid == this.queryParam.customer_id) {
        if (this.isJsonString(item.display)) {
          // 话术
          let data = JSON.parse(item.display);
          this.sendMaterial(data);
        } else {
          let data = {
            msgtype: 'text',
            text: {
              content: item.display //文本内容
            }
          };
          this.$wx.invoke('sendChatMessage', data);
        }
      }
      this.sendContact(obj);
    },
    // 任务列表发送话术
    sendChat(record) {
      let content = record.display;
      let isCopy = false;
      if (typeof content === 'string') {
        isCopy = copyText(content);
        if (isCopy) {
          this.$message.success('复制成功');
        }
      } else {
        if (content.type && content.type == 'text') {
          isCopy = copyText(content.data);
          if (isCopy) {
            this.$message.success('复制成功');
          }
        } else {
          this.$message.error('此类型不支持复制');
        }
      }
      let obj = {
        external_userid: record.external_userid,
        customer_id: record.customerid,
        talk_id: record.id
      };
      this.sendContact(obj);
    },

    // 复制函数
    copy(content) {
      let result;
      let textarea = document.createElement('textarea');
      textarea.value = content;
      textarea.readOnly = 'readOnly';
      document.body.appendChild(textarea);
      textarea.select(); // 选中文本内容
      textarea.setSelectionRange(0, content.length);
      result = document.execCommand('copy');
      document.body.removeChild(textarea);
      if (result) {
        this.$message.success('复制成功');
      } else {
        this.$message.error('复制失败');
      }
    },
    // 联系客户
    sendContact(obj) {
      let _this = this;
      this.$wx.openEnterpriseChat({
        // 注意：userIds和externalUserIds至少选填一个。内部群最多2000人；外部群最多500人；如果有微信联系人，最多40人
        userIds: '', //参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
        externalUserIds: obj.external_userid, // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
        groupName: '', // 会话名称。单聊时该参数传入空字符串""即可。
        chatId: '', // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
        success() {
          postAction(_this.url.concatUrl, {
            customerId: obj.customer_id,
            taskId: obj.talk_id
          }).then((res) => {
            if (res) {
              _this.dataSource = [];
              _this.queryParam.page = 1;
              _this.ipagination.current = 1;
              _this.loadData();
            }
          });
        },
        fail: function(res) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        }
      });
    },

    // 是否json字符串
    isJsonString(str) {
      try {
        if (typeof JSON.parse(str) == 'object') {
          return true;
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
      return false;
    }
  }
};
