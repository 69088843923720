<template>
    <div class="content">
        <a-spin :spinning="loading">
            <div title="所有标签" class="my-tags">
                <div class="tag-box">
                    <div v-for="(item, index) in tagData" :key="index">
                        <div class="title">
                            <div class="name">{{item.title}}</div>

                            <a-button v-if="item.fastOp == 1" size="small" ghost slot="extra" type="danger" @click="delQuick(item)" :loading="item.btnLoading">
                                取消快捷操作
                            </a-button>
                            <a-button v-else size="small" slot="extra" type="" @click="addQuick(item)" :loading="item.btnLoading">
                                添加快捷操作
                            </a-button>
                        </div>
                        <div class="tag-box-cont">
                            <template v-if="isDefault">
                                <a-checkbox-group @change="checkboxChange" name="checkboxgroup[index]" v-model="checkedList[index]" :options="item.tags" />
                            </template>
                            <template v-else>
                                <a-checkbox-group @change="e => {checkboxChange(e, index)}" :disabled="item.is_default == 1" name="checkboxgroup[index]" v-model="checkedList[index]" :options="item.tags" />
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div title="今日联系提醒" class="today-tips">
                <div class="title">今日联系提醒 <a-button class="btn" type="primary" @click="visible = true">新建提醒</a-button>
                </div>
                <div class="tips-box">
                    <div class="item" v-for="(item, index) in tipListData" :key="index">
                        <div class="name">
                            触发提醒标签： <a-tag color="cyan">{{item.tag_name}}</a-tag>
                        </div>
                        <div class="tips">{{item.remind_note}}</div>
                        <a-button class="btn" type="primary" @click="tipDel(item.id)">我已联系</a-button>
                    </div>
                </div>
                <div class="tips-box">
                    <div class="item" v-for="(item, index) in customTipListData" :key="index">
                        <div class="name">
                            触发提醒标签： <a-tag color="cyan">{{item.task_name}}</a-tag>
                        </div>
                        <div class="tips">{{item.display}}</div>
                        <a-button class="btn" type="primary" @click="customTipDel(item.cid)">我已联系</a-button>
                    </div>
                </div>

            </div>

            <a-modal :visible="visible" :confirm-loading="confirmLoading" @ok="onSubmit" @cancel="resetForm">
                <a-form-model ref="ruleForm" :model="form" :rules="rules" :labelCol="{span: 0, offset: 10}">
                    <a-form-model-item ref="delay" label="" prop="delay">
                        提醒时间：
                        <a-input-number v-model="form.delay" :min="1" /> 天后提醒
                    </a-form-model-item>
                    <a-form-model-item ref="content" label=" " prop="content" style="margin-bottom: 0">
                        <a-textarea v-model="form.content" placeholder="请输入提醒跟进内容" />
                    </a-form-model-item>
                </a-form-model>
            </a-modal>
        </a-spin>
    </div>
</template>

<script>
    import { getAction, postAction, deleteAction } from '@/api/manage'
    import { ListMixin } from '@/mixins/ListMixin'
    export default {
        mixins: [ListMixin],
        props: {
            // 是否可以选择系统标签
            isDefault: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                isSend: false,
                tagData: [], //标签
                checkedList: [],    // 选中标签组
                url: {
                    list: 'scrm/work-state/get-user-tag',
                    tagList: 'scrm/work-state/tag-list', //标签列表

                    add: 'scrm/tag/create',
                    delete: 'scrm/tag/delete-user-tag',

                    tipList: 'scrm/work-state/tag-cron-list',
                    tipDel: 'scrm/work-state/del-notice',

                    customTipAdd: 'scrm/self-remind/create',
                    customTipList: 'scrm/task-customization-cron/index',
                    customTipDel: 'scrm/task-customization-cron/delete',

                    addQuick: 'scrm/fast-operation/create',
                    delQuick: 'scrm/fast-operation/delete/1'
                },
                selected: [],
                tagsName: [], //所有标签名字
                corpid: '',
                wechatLogin: '',
                token: '',
                queryParam: {
                    customer_id: ''
                },
                visible: false,
                confirmLoading: false,
                form: {
                    delay: '',
                    content: '',
                    customerIds: ''
                },
                rules: {
                    delay: [
                        { required: true, message: '请输入提醒时间', trigger: 'blur' }
                    ],
                    content: [
                        { required: true, message: '请输入提醒跟进内容', trigger: 'blur' }
                    ],
                },
                tipListData: [],    // 标签列表
                customTipListData: [],  // 自定义提醒列表
            }
        },
        created() {
          this.loadData();
          this.getTagList();
          this.getTipList();
          this.getcustomTipList();
        },
        methods: {
            getTagList() {
                getAction(this.url.tagList).then((res) => {
                    if(!res.length) {return}
                    let data = res.map(item => {
                        item.btnLoading = false;
                        return item;
                    });
                    data.map((item) => {
                        if (item.tags) {
                            item.tags.forEach((childItem) => {
                                childItem.label = childItem.name;
                                childItem.value = childItem.id;
                                let obj = {
                                    label: childItem.name,
                                    value: childItem.id
                                }
                                this.tagsName.push(obj);
                                return childItem;
                            })
                        }
                    })
                    this.tagData = data;
                })
            },

            // 数组去重
            ArrayToHeavy(arr) {
                var result = arr.reduce(function(v, i) {
                    if (Array.isArray(i)) {
                        i.forEach(function(item) {
                            if (v.indexOf(item) < 0) {
                                v.push(item);
                            }
                        });
                    } else {
                        if (v.indexOf(i) < 0) {
                            v.push(i);
                        }
                    }
                    return v;
                }, []);
                return result;
            },

            // 删除数组内重复数
            DelSameArray(arr1, arr2) {
                arr1 = arr1.filter(item => {
                    let idList= arr2.map(v => v.id)
                    return !idList.includes(item.id)
                })
            },

            resultSameValue(arr1, arr2) {
                let newArr = Array.from(new Set([...arr1].filter(x => arr2.includes(x))));
                return newArr;
            },

            // 改变标签
            checkboxChange(e, index) {
                let url;
                let arr = [];
                let newSelected = this.resultSameValue(this.selected, this.tagData[index].tags.map(item => {return item.id}));

                let data = {};
                if(newSelected.length > e.length) {
                    // 删除
                    url = this.url.delete
                    arr = newSelected.filter(item => {
                        if (!e.includes(item)) return item;
                    });
                    data = {
                        tag_id: arr,
                        customer_id: this.queryParam.customer_id
                    }
                }
                else {
                    // 增加
                    url = this.url.add
                    arr = e.filter(item => {
                        if (!newSelected.includes(item)) return item;
                    })
                    data = {
                        tag_id: arr,
                        customer_id: [this.queryParam.customer_id],
                        is_async: 2
                    }
                }
                this.loading = true;
                postAction(url, data).finally(() => {
                    this.loadData();
                    this.getTipList();
                });
            },

            // 今日联系列表
            getTipList() {
                let data = {
                    customer_id: this.queryParam.customer_id
                }
                getAction(this.url.tipList, data).then((res) => {
                    this.tipListData = res;
                });
            },

            // 我已联系
            tipDel(cron_id) {
                if(this.isSend) {
                    return
                }
                let data = {
                    customer_id: this.queryParam.customer_id,
                    cron_id: cron_id
                }
                this.isSend = true;
                getAction(this.url.tipDel, data).then(() => {
                    this.$message.success('确定成功');
                    this.getTipList();
                }).finally(() => {
                    this.isSend = false;
                })
            },

            // 新建提醒
            onSubmit() {
                this.$refs.ruleForm.validate(valid => {
                    if (!valid) {
                        return;
                    }
                    this.form = Object.assign({}, this.form, {
                        customerIds: JSON.stringify([this.queryParam.customer_id])
                    })
                    this.confirmLoading = true;
                    postAction(this.url.customTipAdd, this.form).then(() => {
                        this.$message.success('添加成功');
                        this.resetForm();
                        this.getcustomTipList();
                    }).catch(() => {
                        this.confirmLoading = false;
                    })
                });
            },

            // 取消新建提醒
            resetForm() {
                this.$refs.ruleForm.resetFields();
                this.visible = false;
                this.confirmLoading = false;
            },

            // 今日联系列表
            getcustomTipList() {
                let data = {
                    customerId: this.queryParam.customer_id
                }
                getAction(this.url.customTipList, data).then((res) => {
                    this.customTipListData = res.data;
                });
            },
            // 自定义提醒我已联系
            customTipDel(cron_id) {
                if(this.isSend) {
                    return
                }
                let data = {
                    id: cron_id
                }
                this.isSend = true;
                deleteAction(this.url.customTipDel, data).then(() => {
                    this.$message.success('确定成功');
                    this.isSend = false;
                    this.getcustomTipList();
                }).catch(() => {
                    this.isSend = false;
                })
            },

            // 添加快捷操作
            addQuick(item) {
                item.btnLoading = true;

                let obj = {
                    type: 1, // 1标签 2话术
                    group_id: item.id
                };
                postAction(this.url.addQuick, obj).then(() => {
                    item.fastOp = 1;
                    this.$message.success('添加成功！')
                })
                .finally( () => {
                    item.btnLoading = false;
                })
            },

            // 取消快捷操作
            delQuick(item) {
                item.btnLoading = true;

                let obj = {
                    type: 1,    // 1标签 2话术
                    group_id: item.id
                };
                deleteAction(this.url.delQuick, obj).then(() => {
                    item.fastOp = 0;
                    this.$message.success('删除成功！')
                })
                .finally( () => {
                    item.btnLoading = false;
                })
            }
        },
        watch: {
            selected() {
                this.checkedList = [];
                this.tagData.forEach(() => {
                    this.checkedList.push(this.selected)
                })
            },
            tagData() {
                this.checkedList = [];
                this.tagData.forEach(() => {
                    this.checkedList.push(this.selected)
                })
            },
            dataSource() {
                this.selected = this.dataSource.map(item => {
                    return item.id
                })
            }
        }
    }
</script>

<style lang="less">
    // 标签弹窗
    .my-tags {
        .tag-box {
            .title {
                margin-top: 20px;
                padding: 15px 20px;
                font-weight: 700;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: #f1f1f1;
            }
            &-cont {
                padding: 20px 0;
                vertical-align: middle;
                * {
                    vertical-align: middle;
                }
                .input-w {
                    width: 150px;
                }
                .pad {
                    padding-top: 5px;
                    padding-left: 10px;
                }
            }
            .col-sp {
                display: inline-block;
                width: 440px;
                .ant-input, .ant-btn {
                    margin: 0 10px;
                }
            }
            .ant-tag {
                cursor: pointer;
            }
        }
        .ant-checkbox-group-item {
            border-radius: 10px;
            color: #9b9b9b;
            border: 2px solid #9b9b9b;
            margin-bottom: 10px;
            margin-right: 10px;
            padding: 10px 20px;
            font-size: 26px;
            line-height: 40px;
            span {
                color: #333;
            }
            .ant-checkbox {
                display: none;
            }
            &:hover {
                .ant-checkbox-inner {
                    display: none;
                    background: none;
                }
            }
        }
        .ant-checkbox-wrapper-disabled {
            border: 2px solid #eee;
            border-radius: 10px;
            span {
                color: #bbb;
            }
        }
        .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
            border: 2px solid #13c2c2 !important;
            border-radius: 10px;
            font-size: 26px;
            background: #13c2c2;
            span {
                color: #fff;
            }
            &::after {
                display: none;
            }
        }
        .ant-checkbox + span {
            padding: 0;
        }
        .ant-checkbox-inner {
            display: none;
            background: none;
            border: 0;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
            display: none;
            background: none;
            border: 0;
        }
    }
</style>
<style lang="less" scoped>
.content {
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 380px);
}
.today-tips {
    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;
    }
    .item {
        border: 2px solid #eee;
        border-radius: 10px;
        margin-top: 20px;
        padding-top: 20px;
        .name {
            padding: 0 20px;
            font-size: 28px;
            line-height: 60px;
            color: #333;
        }
        .tips {
            padding: 0 20px;
            font-size: 26px;
            line-height: 40px;
            color: #999;
            word-break: break-all;
        }
        .btn {
            width: 100%;
            margin-top: 20px;
        }
    }
}
</style>
