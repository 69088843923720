
/*
 ** 时间戳转换成指定格式日期
 ** eg.
 ** dateFormat(11111111111111, 'Y年m月d日 H时i分')
 ** → "2322年02月06日 03时45分"
 */
export function dateFormat(timestamp, formats) {
    // formats格式包括
    // 1. Y-m-d
    // 2. Y-m-d H:i:s
    // 3. Y年m月d日
    // 4. Y年m月d日 H时i分
    formats = formats || 'Y-m-d';

    var zero = function(value) {
        if (value < 10) {
            return '0' + value;
        }
        return value;
    };

    var myDate = timestamp ? new Date(timestamp) : new Date();

    var year = myDate.getFullYear();
    var month = zero(myDate.getMonth() + 1);
    var day = zero(myDate.getDate());

    var hour = zero(myDate.getHours());
    var minite = zero(myDate.getMinutes());
    var second = zero(myDate.getSeconds());

    return formats.replace(/Y|m|d|H|i|s/ig, function(matches) {
        return ({
            Y: year,
            m: month,
            d: day,
            H: hour,
            i: minite,
            s: second
        })[matches];
    });
}

export function dayTimeFormat(time) {
    var theTime = time;
    var minutes = 0, hours = 0, days = 0;
    if (theTime > 60) {
        minutes = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (minutes > 60) {
            hours = parseInt(minutes / 60);
            minutes = parseInt(minutes % 60);
            if (hours > 24) {
                //大于24小时
                days = parseInt(hours / 24);
                hours = parseInt(hours % 24);
            }
        }
    }
    var result = '';
    if (minutes > 0) {
        result = "" + parseInt(minutes) + "分";
    }
    if (hours > 0) {
        result = "" + parseInt(hours) + "小时" + result;
    }
    if (days > 0) {
        result = "" + parseInt(days) + "天" + result;
    }
    return result;
}

// 复制文本
export function copyText(content) {
    let textarea = document.createElement('textarea');
    textarea.value = content;
    textarea.readOnly = 'readOnly';
    document.body.appendChild(textarea);
    textarea.select(); // 选中文本内容
    textarea.setSelectionRange(0, content.length);
    document.execCommand('copy');
    document.body.removeChild(textarea);
    return true;
}


