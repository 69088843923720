import axios from 'axios'
import storage from 'store'
import notification from 'ant-design-vue/es/notification'
import message from 'ant-design-vue/es/message'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import qs from 'qs';
import store from '../store'

// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 6000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
    if (error.response) {
        const data = error.response.data
        // 从 localstorage 获取 token
        if (error.response.status === 400 || error.response.status === 500) {
            message.error(data.message)
        }
        else if (error.response.status === 403) {
            notification.error({
                message: 'Forbidden',
                description: data.message
            })
        }
        else if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
            notification.error({
                message: data.message,
                description: data.name
            })
        }
        else if (error.response.status === 402) {
            //token校验失败，直接删除token，重载页面
            store.dispatch('delToken').then(() => {
                storage.remove(ACCESS_TOKEN);
                location.reload();
            });
        }
    }
    return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
    const token = store.state.auth.token;
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    if (token) {
        config.headers['JWT-Token'] = token
    }
    if (config.method === 'post' || config.method === 'put' || config.method === 'delete') {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        config.data = qs.stringify(config.data);
    }
    return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
    if (Object.prototype.hasOwnProperty.call(response.headers, 'x-pagination-page-count')) {
        return {
            pageSize: response.headers['x-pagination-per-page'] - 0,
            pageNo: response.headers['x-pagination-current-page'] - 0,
            totalCount: response.headers['x-pagination-total-count'] - 0,
            totalPage: response.headers['x-pagination-page-count'] - 0,
            data: response.data
        }
    } else {
        return response.data;
    }
    //   return response.data;
}, errorHandler)

const installer = {
    vm: {},
    install(Vue) {
        Vue.use(VueAxios, request)
    }
}

export default request

export {
    installer as VueAxios,
    request as axios
}
