<template>
    <a-spin :spinning="loading">
        <div class="content">
            <div class="poster-item">
                <a-input-search v-model="queryParam.name" placeholder="请输入海报名称" :allowClear="true" @search="onSearch" @change="onChange" />
            </div>
            <div class="poster-item">
                <a-select show-search :filter-option="filterOption" placeholder="下拉选择海报渠道" v-model="channelVal" allowClear class="select" @change="channelSelect">
                    <a-select-option v-for="item in channelList" :value="item.id">
                        【{{item.id}}-{{item.name}}】-{{item.sale_id}}-{{item.sale_name}}
                    </a-select-option>
                </a-select>
            </div>
            <div v-if="dataSource.length" class="goods-list" @scroll="onScroll">
                <ul>
                    <li v-for="(item, index) in dataSource" :key="index" class="pointer">
                        <div class="img-box">
                            <img :src="item.cover">
                            <div class="text">
                                <div class="title">{{item.name}}</div>
                                <div class="price"><span>￥</span>{{(item.price / 100).toFixed(2)}}元</div>
                            </div>
                        </div>
                        <div class="channel">
                            <template v-if="channelVal != undefined">
                                <p>渠道id：{{channelSelectArr[0].id}}</p>
                                <p>渠道名称：{{channelSelectArr[0].name}}</p>
                            </template>
                            <template v-else>
                                <p>渠道id：无</p>
                                <p>渠道名称：无</p>
                            </template>
                            <template v-if="channelVal != undefined">
                                <p>销售id：{{channelSelectArr[0].sale_id}}</p>
                                <p>销售名称：{{channelSelectArr[0].sale_name}}</p>
                            </template>
                            <template v-else>
                                <p>销售id：无</p>
                                <p>销售名称：无</p>
                            </template>
                        </div>
                        <div class="other">
                            <a-button @click.stop="copyPoster(item)" class="sale-btn">复制链接</a-button>
                            <a-button type="primary" @click.stop="sendPoster(item)" class="sale-btn"><span>发送[</span><span class="sale-name">{{channelName}}</span><span>]链接</span></a-button>
                        </div>
                    </li>
                </ul>
                <load-more :status="loadStatus"></load-more>
            </div>
            <div v-else>
                <a-empty :image="simpleImage" description="暂无海报" />
            </div>
        </div>
    </a-spin>
</template>

<script>
    import { Empty } from 'ant-design-vue';
    import { getAction } from '@/api/manage'
    import { ListMixin } from '@/mixins/ListMixin'
    import loadMore from '@/components/loadMore.vue'
    import { copyText } from '@/utils/util'
    export default {
        name: 'PosterListModal',
        mixins: [ListMixin],
        components: {
            loadMore,
        },
        beforeCreate() {
            this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        },
        data() {
            return {
                loading: false,
                url: {
                    channelUrl: 'scrm/work-state/get-beltline-channels',
                    posterUrl: 'scrm/work-state/get-beltline-posters'
                },
                isSend: false, // 防止重复获取
                loadStatus: 'loading',
                queryParam: {
                    name: '',
                },
                ipagination: {
                    pageSize: 5
                },
                channelVal: undefined, //渠道
                channelSelectArr: [], //选择的渠道
                channelName: '无渠道', //无渠道
                channelList: [], //渠道列表
            }
        },
        created() {
            this.getChannel();
            // this.getDetails();
        },
        mounted() {},
        methods: {
            // 发送渠道
            sendPoster(item) {
                let link = '';
                if (this.channelVal == undefined) {
                    link = item.copy_link;
                }
                else {
                    link = `${item.copy_link}?c=${this.channelSelectArr[0].id}&s=${this.channelSelectArr[0].sale_id}`
                }
                let obj = {
                    link: link, //H5消息页面url 必填
                    title: item.share_title ? item.share_title : item.name, //H5消息标题
                    desc: item.share_text ? item.share_text : '', //H5消息摘要
                    imgUrl: item.share_image ? item.share_image : item.cover, //H5消息封面图片URL
                }
                this.sendCard(item.id, 5, obj);
            },
            // 获取渠道列表
            getChannel() {
                getAction(this.url.channelUrl).then(res => {
                    this.channelList = res;
                });
            },
            // 获取海报列表
            getDetails() {
                this.loading = false;
                if (this.isSend) { return }
                var params = this.getQueryParams(); //查询条件
                if (this.ipagination.current == 1) {
                    this.loading = true;
                    this.dataSource = [];
                }
                this.isSend = true;
                this.loadStatus = 'loading';
                getAction(this.url.posterUrl, params).then(res => {
                    if (res.data && res.data.length >= 0) {
                        this.dataSource = this.dataSource.concat(res.data);
                        if (res.next_page == null) {
                            this.loadStatus = 'noMore'
                        } else {
                            this.loadStatus = 'loading'
                            this.ipagination.current++
                        }
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.isSend = false;
                });
            },
            // 加载更多
            onScroll(e) {
                if (this.loadStatus == 'noMore') {
                    return
                }
                if (e.target.scrollTop + e.target.clientHeight + 400 >= e.target.scrollHeight) {
                    this.getDetails()
                }
            },
            // 选择渠道
            channelSelect(e) {
                this.channelVal = e;
                if(this.channelVal == undefined) {
                    this.channelName= '无渠道';
                }
                else {
                    this.channelSelectArr = this.channelList.filter((item) => {
                        return item.id == this.channelVal;
                    });
                    this.channelName = this.channelSelectArr[0].name;
                }

            },
            // 搜索
            onSearch() {
                if (this.queryParam.name != '') {
                    this.ipagination.current = 1;
                    this.getDetails();
                }
            },
            onChange() {
                if (this.queryParam.name == '') {
                    this.dataSource = [];
                }
            },
            // 海报渠道搜索
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
            // 复制链接
            copyPoster(item) {
                let link = '';
                if (this.channelVal == undefined) {
                    link = item.copy_link;
                }
                else {
                    link = `${item.copy_link}?c=${this.channelSelectArr[0].id}&s=${this.channelSelectArr[0].sale_id}`
                }
                let isCopy = copyText(link);
                if (isCopy) {
                    this.$message.success('复制成功');
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
    .poster-item {
        padding-bottom: 20px;
        .select {
            width: 100%;
        }
    }
    .channel {
        p {
            margin-bottom: 0;
            margin-top: 5px;
        }
    }
    .goods-list li .other {
        padding-top: 20px;
        align-items: flex-end;
    }
    .sale-btn {
        span {
            display: inline-block;
            vertical-align: middle;
        }
    }
    .sale-name {
        display: inline-block;
        max-width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
</style>
