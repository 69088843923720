<template>
    <a-modal title="选择部门或员工" width="700px" :visible="visible" @ok="handleOk" @cancel="() => { $emit('cancel')}">
        <div class="staff-model-box">
            <a-row>
                <a-col :span="16">
                    <div class="staff-box">
                        <div class="staff-box-top">
                            <a-input-search v-model="searchStr" allow-clear placeholder="搜索部门或员工" @change="onSearch" />
                        </div>
                        <div class="loading" v-if="loading">
                            <a-spin />
                        </div>
                        <div class="staff-box-list" v-else>
                            <a-tree :treeData="treeData" :replaceFields="replaceFields" :selectedKeys="selectedKeys" blockNode multiple @select="onSelect" v-if="!isSearch" :default-expanded-keys="defaultKeys">
                                <template slot="custom" slot-scope="item">
                                    <a-row>
                                        <a-col :span="20">
                                            <a-space :size="5">
                                                <a-avatar size="small" :src="item.avatar" v-if="item.avatar" />
                                                <a-icon type="folder" v-else />
                                                <span class="name">{{item.name}}</span>
                                            </a-space>
                                        </a-col>
                                        <a-col :span="4" align="right" class="check">
                                            <a-icon type="check" />
                                        </a-col>
                                    </a-row>
                                </template>
                            </a-tree>
                            <div class="search-list" v-else>
                                <template v-for="(item, index) in searchTreeData">
                                    <div class="select-ant" :class="{'select-ant-check':item.isSelect == true}" @click="serachSelect(item.key, index)">
                                        <a-row>
                                            <a-col :span="20">
                                                <a-space :size="5">
                                                    <a-avatar size="small" :src="item.avatar" v-if="item.avatar" />
                                                    <a-icon type="folder" v-else />
                                                    <span class="name">{{item.name}}</span>
                                                </a-space>
                                            </a-col>
                                            <a-col :span="4" align="right">
                                                <a-icon type="check" />
                                            </a-col>
                                        </a-row>
                                    </div>
                                </template>
                            </div>
                        </div>

                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="staff-select">
                        <div class="tit">
                            <a-row>
                                <a-col :span="12">
                                    <a-space :size="5">
                                        <span>已选</span>
                                        <span>{{temSelectData.length}}</span>
                                    </a-space>
                                </a-col>
                                <a-col :span="12" align="right">
                                    <span class="clear" @click="emptySelect()">清空</span>
                                </a-col>
                            </a-row>
                        </div>
                        <div class="staff-select-list">
                            <template v-for="item in temSelectData">
                                <a-row>
                                    <a-col :span="20">
                                        <a-space :size="5">
                                            <a-avatar size="small" :src="item.avatar" v-if="item.avatar" />
                                            <a-icon type="folder" v-else />
                                            <span class="name">{{item.name}}</span>
                                        </a-space>
                                    </a-col>
                                    <a-col :span="4" align="right">
                                        <a-icon type="close" class="close" @click="removeSelect(item.key)" />
                                    </a-col>
                                </a-row>
                            </template>
                        </div>
                    </div>
                </a-col>
            </a-row>
        </div>
    </a-modal>
</template>

<script>
    import { getAction, putAction, postAction, deleteAction, getTableList } from '@/api/manage'
    export default {
        props: {
            visible: {
                type: Boolean
            },
            isType: {
                type: String //type=1, //部门或员工type=2 //部门
            },
            checkDept: {
                type: Number,
                default: 0,
            }
        },
        data() {
            return {
                url: {
                    staffList: 'api/admin-user/index', //部门员工列表
                    departmentList: 'api/admin-department/index?parent_id=0', //部门列表
                },
                defaultKeys: ['15-1'], //默认展开
                searchTreeData: [], //搜索树
                selectedKeys: [], //选中的部门或员工
                temporaryData: [], //所有的部门和员工
                temSelectData: [], //选择的部门或员工
                autoExpandParent: true, //是否自动展开父节点
                searchStr: '', //搜索部门Str
                isSearch: false,
                treeData: [], //树结构
                replaceFields: {
                    title: 'name'
                },
                detIds: [], //部门id
                userIds: [], //员工id
                loading: true,

            }
        },
        created() {
            this.gettreeData();
        },
        methods: {
            gettreeData() {
                let url = this.url.staffList;
                getAction(url, {check_my_dept: this.checkDept}).then((res) => {
                    let datas = [res];
                    if (this.isType == 2) {
                        datas = res;
                    }
                    this.defaultKeys = [res.key];
                    // this.treeData = [res];
                    this.getTreeList(datas);
                    this.treeDataGetnode(datas);
                    this.temporaryData = this.unique(this.temporaryData);
                    this.loading = false;
                })
            },
            // 去重
            unique(arr) {
                const res = new Map();
                return arr.filter((arr) => !res.has(arr.key) && res.set(arr.key, 1));
            },
            // 确定
            handleOk() {
                this.userIds = [];
                this.detIds = [];
                let nameArr = [];
                this.temSelectData.forEach(item => {
                    if (item.type == 2) {
                        this.userIds.push(item.id);
                    }
                    if (item.type == 1) {
                        this.detIds.push(item.id);
                    }
                    if (this.isType == 2) {
                        this.detIds.push(item.id);
                    }
                    nameArr.push(item.name);
                })
                this.$emit('ok', this.userIds, this.detIds, nameArr);
            },
            // 遍历树 添加元素
            getTreeList(data) {
                let datas = data;
                datas.forEach((item, index) => {
                    // 判断是否有初始部门
                    if (item.type == '1' && item.checked) {
                        this.selectedKeys.push(item.key);
                        this.temSelectData.push(item);
                        this.$emit('ok', '', item.id, item.name);
                    }
                    item.scopedSlots = { title: 'custom' };
                    if (item.children) {
                        this.getTreeList(item.children);
                    }
                })
                this.treeData = datas;
            },
            // 遍历树结构
            treeDataGetnode(data) {
                let datas = data; //是一个树结构的数据
                datas.forEach((item) => {
                    let obj = {
                        avatar: item.avatar,
                        corp_dept_id: item.corp_dept_id,
                        id: item.id,
                        name: item.name,
                        type: item.type,
                        key: item.key
                    }
                    this.temporaryData.push(obj);
                    if (item.children) {
                        this.treeDataGetnode(item.children);
                    }
                });
            },
            // 点击部门或员工
            onSelect(selectedKeys, info) {
                this.selectedKeys = selectedKeys;
                this.temSelectData = [];
                if (this.selectedKeys.length > 0) {
                    this.selectedKeys.forEach(item => {
                        for (let i = 0; i < this.temporaryData.length; i++) {
                            if (item == this.temporaryData[i].key) {
                                this.temSelectData.push(this.temporaryData[i]);
                            }
                        }
                    })
                }
            },
            // 移除部门或员工
            removeSelect(key) {
                this.temSelectData.forEach((item, index) => {
                    if (item.key == key) {
                        this.temSelectData.splice(index, 1);
                        this.selectedKeys.splice(index, 1);
                    }
                });
                this.searchTreeData.map((item, index) => {
                    if (item.key == key) {
                        return item.isSelect = false;
                    }
                })
            },
            // 清空选中的部门或员工
            emptySelect() {
                this.temSelectData = [];
                this.selectedKeys = [];
                this.checkedKeys = [];
                this.searchTreeData.map((item, index) => {
                    return item.isSelect = false;
                })
                this.$forceUpdate();
            },
            // 搜索部门或员工
            onSearch() {
                var _this = this;
                // _this.searchStr = _this.searchStr;
                if (_this.searchStr === '') {
                    _this.isSearch = false;
                } else {
                    _this.isSearch = true;
                    //获取所有存在searchValue的节点
                    let candidateKeysList = _this.getkeyList(_this.searchStr, _this.treeData, []);
                    // 遍历搜索出来的部门或员工
                    candidateKeysList.map((item, index) => {
                        let isBoolean = false;
                        // 遍历已选中的部门或员工
                        for (let i = 0; i < _this.temSelectData.length; i++) {
                            if (item.key == _this.temSelectData[i].key) {
                                isBoolean = true;
                            }
                        }
                        return item.isSelect = isBoolean;
                    })
                    _this.searchTreeData = this.unique(candidateKeysList);
                }
            },
            // 点击选取
            serachSelect(key, index) {
                this.searchTreeData[index].isSelect = !this.searchTreeData[index].isSelect;
                this.$forceUpdate();
                // 判断是否有选中的部门或员工
                if (this.searchTreeData[index].isSelect == false & this.temSelectData.length != 0) {
                    for (let i = 0; i < this.temSelectData.length; i++) {
                        if (key == this.temSelectData[i].key) {
                            this.temSelectData.splice(i, 1);
                            this.selectedKeys.splice(i, 1);
                        }
                    }
                } else {
                    this.temSelectData.push(this.searchTreeData[index]);
                    this.selectedKeys.push(key);
                }
            },
            //获取节点中含有value的所有key集合
            getkeyList(value, tree, keyList) {
                //遍历所有同一级的树
                for (let i = 0; i < tree.length; i++) {
                    let node = tree[i]; //如果该节点存在value值则push
                    if (node.name.indexOf(value) > -1) {
                        keyList.push(tree[i]);
                    }
                    //如果拥有孩子继续遍历
                    if (node.children) {
                        this.getkeyList(value, node.children, keyList);
                    }
                }
                //因为是引用类型，所有每次递归操作的都是同一个数组
                return keyList;
            },
        }
    }
</script>

<style lang="less" scoped>
    .loading {
        text-align: center;
        padding-top: 20px;
    }
    .staff-model-box {
        height: 440px;
        .ant-row {
            display: flex;
            align-items: center;
        }
        .staff-box {
            height: 440px;
            margin: 0 30px 0 0;
            &-top {
                position: relative;
            }
            &-list {
                height: 400px;
                overflow-y: auto;
                line-height: 30px;
                .check {
                    display: none;
                    color: #ff4d4f;
                }
                .ant-tree-node-selected {
                    .check {
                        display: block;
                        height: 24px;
                        margin-top: 0;
                        line-height: 24px;
                        background: none;
                        box-shadow: none;
                        text-align: right;
                        padding-right: 5px;
                    }
                }

            }
        }
        .staff-select {
            height: 440px;
            overflow: auto;
            line-height: 30px;
            .ant-space {
                vertical-align: middle;
            }
            .clear, .close {
                cursor: pointer;
            }
            .close {
                color: #ff4d4f;
            }
        }
        .search-list {
            padding-top: 5px;
            .select-ant {
                padding: 0 5px;
                cursor: pointer;
                .anticon-check {
                    display: none;
                }
                &:hover {
                    background: #e6fffb;
                }
                &-check {
                    background: #b5f5ec;
                    .anticon-check {
                        display: inline-block;
                        color: #ff4d4f;
                    }
                    &:hover {
                        background: #b5f5ec;
                    }
                }

            }
        }
    }
</style>
<style lang="less">
    .staff-box {
        .ant-tree {
            li {
                .ant-tree-node-selected {
                    height: auto;
                }
            }
        }
    }
    .staff-select {
        &-list {
            padding-top: 10px;
        }
        .ant-row {
            padding: 5px 0;
        }
    }
</style>