<template>
    <div class="more-cont">
        <div class="more" v-if="status == 'loading'">
            <a-spin />
        </div>
        <div class="more" v-if="status == 'noMore'">
            <span class="no-cont">没有更多数据</span>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
    export default {
        props: [
            'status'
        ],
        data() {
            return {

            }
        },
        created() {


        },
        methods: {

        }
    }
</script>

<style lang="less" scoped>
    @import '../style/less/func';
    .more-cont {
        .h(140);
    }
    .more {
        // .pds(b, 72);
        .h(140);
        .lh(80);
        .pd(30, 0);
        text-align: center;
        .fz(32);
        color: #62bb9d;
        .icon-loading {
            .pos(r);
            .fz(82);
            color: #a2e2cd;
            .dis(ib);
            .w(82);
            .h(82);
            &:before {
                .pos(a);
                top: 0;
                left: 0;
                -webkit-animation: rotate 2s linear 0s infinite both;
                animation: rotate 2s linear 0s infinite both;
            }
        }
        .no-cont {
            color: #999;
            text-decoration: none;
        }

    }
    .goto-more {
        text-align: center;
        color: #13C2C2;
        .lh(50);
        .h(140);
        .pd(20, 0);
        .icon-jiantou {
            .fz(60);

        }
        .more-text {
            .fz(32);
        }
    }
</style>