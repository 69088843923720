const emojiIcon = [
    [
        '😁',
        '😷',
        '😂',
        '😝',
        '😳',
        '😱',
        '😔',
        '😒',
        '👻',
        '🙏',
        '💪',
        '🎉',
        '🎁',
        '😊',
        '😃',
        '😉',
        '😍',
        '😘',
        '😚',
        '😌',
        '😜',
        '😏',
        '😓',
        '😞',
        '😖',
        '😢',
        '😰',
        '😨',
        '😣',
        '😭',
        '😵',
        '😠',
        '😡',
        '😪',
        '👿',
        '👽',
        '❤',
        '💔',
        '💘',
        '✨',
        '💤',
        '💦',
        '🎵',
        '🔥',
        '💩',
        '👍',
        '👎',
        '👊',
        '✌',
        '👆',
        '👇',
        '👉',
        '👈',
        '☝',
        '💏',
        '💑',
        '👦',
        '👧',
        '👩',
        '👨'
    ],
    [
        '💋',
        '☀',
        '☔',
        '☁',
        '⛄',
        '🌙',
        '⚡',
        '🌹',
        '🌺',
        '🌴',
        '🌵',
        '💝',
        '🎃',
        '🎅',
        '🎄',
        '🔔',
        '🎈',
        '💿',
        '📷',
        '🎥',
        '💻',
        '📺',
        '☎',
        '🔓',
        '🔒',
        '🔑',
        '🔨',
        '💡',
        '📫',
        '🛀',
        '💰',
        '💣',
        '🔫',
        '💊',
        '🏈',
        '🏀',
        '⚽',
        '⚾',
        '⛳',
        '🏆',
        '👾',
        '🎤',
        '🎸',
        '👙',
        '👑',
        '🌂',
        '👜',
        '💄',
        '💍',
        '💎',
        '☕',
        '🍺',
        '🍻',
        '🍸',
        '🍔',
        '🍟',
        '🍝',
        '🍣',
        '🍜',
        '🍳',
        '🍦',
        '🎂',
        '🍅',
        '✈',
        '🚀',
        '🍉'
    ]
]

export {
    emojiIcon
}
