import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { VueAxios } from './utils/request'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import 'amfe-flexible';
import ConfigProvider from 'ant-design-vue';

import './utils/filter' // global filter
import 'babel-polyfill'

Vue.prototype.$wx = window.wx;

Vue.use(ConfigProvider);
Vue.use(VueAxios);
Vue.use(Antd);

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
