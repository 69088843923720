<template>
    <a-spin :spinning="loading" v-if="visible">
        <div class="content">
            <div class="goods-list">
                <div class="back-box">
                    <div class="back" @click="back">
                        <a-icon type="arrow-left" /> 返回课程列表
                    </div>
                </div>
                <ul v-if="dataSource.length">
                    <li v-for="(item, index) in dataSource" :key="index">
                        <div class="img-box">
                            <a-icon class="icon" type="container" />
                            <div class="text">
                                <div class="title">{{item.name}}</div>
                            </div>
                        </div>
                        <div class="other">
                            <span class="times">时长：{{timeFormat(item.duration)}}</span>
                            <a-button type="primary" @click="sendCourse(item, 'chapter')">发送</a-button>
                        </div>
                    </li>
                </ul>
                <div v-else>
                    <a-empty :image="simpleImage" description="暂无课程章节" />
                </div>
            </div>
        </div>
    </a-spin>
</template>

<script>
    import { Empty } from 'ant-design-vue';
    import { getAction } from '@/api/manage'
    import { ListMixin } from '@/mixins/ListMixin'
    export default {
        name: 'GourseListModal',
        props:['title'],
        mixins: [ListMixin],
        beforeCreate() {
            this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        },
        data() {
            return {
                loading: true,
                url: {
                    list: 'scrm/work-state/course-detail'
                },
                queryParam: {
                    title: '',
                    customer_id: '',
                    lid: ''
                },
                ipagination: {
                    pageSize: 1000
                },
                isSend: false, // 防止重复获取
                visible: false,
                ctype: '',
                cover: ''
            }
        },
        created() {
        },
        mounted() {
        },
        methods: {

            show(record) {
                this.visible = true;
                this.dataSource = [];
                this.loading = true;
                this.queryParam.lid = record.id;
                this.ctype = record.ctype;
                this.cover = record.cover;
                this.getDetails();
            },

            getDetails() {
                if(this.isSend) {return}
                var params = this.getQueryParams(); //查询条件
                if(this.ipagination.current == 1) {
                    this.loading = true;
                    this.dataSource = [];
                }
                this.isSend = true;
                this.loadStatus = 'loading'
                getAction(this.url.list, params).then((res) => {
                    if (res.data && res.data.list.length >= 0) {
                        res.data.list.map(item => {
                            item.ctype = this.ctype;
                            item.cover = this.cover;
                            return item;
                        })
                        this.dataSource = this.dataSource.concat(res.data.list);
                        if (res.data.list.length < this.ipagination.pageSize) {
                            this.loadStatus = 'noMore'
                        }
                        else {
                            this.loadStatus = 'loading'
                            this.ipagination.current++
                        }
                    }
                })
                .finally(res => {
                    this.loading = false;
                    this.isSend = false;
                });
            },

            onScroll(e) {
                if(this.loadStatus == 'noMore') {
                    return
                }
                if(e.target.scrollTop + e.target.clientHeight + 400 >= e.target.scrollHeight) {
                    this.getDetails()
                }
            },

            // 返回
            back() {
                this.visible = false;
                this.$emit('ok')
            },

            timeFormat(time) {
                var min=Math.floor(time%3600);
                var minute = Math.floor(min/60);
                minute = minute > 9 ? minute : '0' + minute;
                var second = time%60;
                second = second > 9 ? second : '0' + second;
                return Math.floor(time/3600) + "时" + minute + "分"+ second + "秒";
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
    // @import '@/style/less/func';
    .default-color {
        color: #13C2C2;
    }
    .back {
        display: inline-block;
        color: #4c4c4c;
        font-weight: 300;
        padding: 10px 20px 10px 0;
        cursor: pointer;
        &:hover {
            color: #13c2c2;
        }
    }
</style>
