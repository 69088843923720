<template>
  <div :class="customerId == 0 ? 'content content-group' : 'content'" v-if="visible">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="话术分组" prop="class_id">
        <a-select placeholder="请选择话术分组" v-model="form.class_id" :disabled="!isAdd">
          <a-select-option v-for="(item, index) in dataSource" :key="index" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="话术标题" prop="title">
        <a-input placeholder="请选择话术标题" v-model="form.title" />
      </a-form-model-item>

      <a-form-model-item label="话术类型" prop="type">
        <a-radio-group v-model="form.type" name="type" @change="radioChange" :disabled="!isAdd">
          <a-radio value="text">文字</a-radio>
          <a-radio value="image">图片</a-radio>
          <a-radio value="video">视频</a-radio>
          <a-radio value="news">图文链接</a-radio>
          <a-radio value="file">文件</a-radio>
          <a-radio value="miniprogram">小程序</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <!-- 文字 start -->
      <template v-if="form.type == 'text'">
        <a-form-model-item label="话术内容" prop="content">
          <a-textarea
            id="textarea"
            ref="singleText"
            placeholder="请填写话术内容"
            :rows="4"
            v-model="form.content"
            @change="inputChange"
          ></a-textarea>
        </a-form-model-item>

        <a-form-model-item label=" " :colon="false" style="margin-top: -30px">
          <a-popover v-model="smileVisible" trigger="click" placement="bottom">
            <a-icon class="icon-smile" type="smile"></a-icon>
            <div slot="content" class="smile-box">
              <emoticon-modal @ok="emoticonOk"></emoticon-modal>
            </div>
          </a-popover>
          <a-tag
            class="tag"
            color="red"
            v-for="(item, index) in tags"
            :key="index"
            @click="insert('[' + item + ']')"
          >
            {{ item }}
          </a-tag>
        </a-form-model-item>
      </template>
      <!-- 文字 end -->
      <!-- 图片 start -->
      <template v-else-if="form.type == 'image'">
        <a-form-model-item label="图片" prop="content">
          <a-upload-dragger
            accept=".png, .jpg, .jpeg"
            :file-list="fileListImage"
            :before-upload="beforeUpload"
            name="file"
            :multiple="false"
            action="/xapi/api/image/create"
            @change="
              (e) => {
                uploadChange(e, 'image');
              }
            "
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">点击上传</p>
            <p class="ant-upload-hint">支持扩展名：.png, .jpg, .jpeg</p>
          </a-upload-dragger>
          <div v-for="(item, index) in fileListImage" :key="index" style="height: 0">
            <img style="width: 0;height: 0" :src="item.url" />
          </div>
        </a-form-model-item>
      </template>
      <!-- 图片 end -->
      <!-- 视频 start -->
      <template v-else-if="form.type == 'video'">
        <a-form-model-item label="视频" prop="videoUrl">
          <a-upload-dragger
            accept=".mp4"
            name="file"
            :file-list="fileListVideo"
            :multiple="false"
            action="/xapi/api/upload/create"
            @change="
              (e) => {
                uploadChange(e, 'video');
              }
            "
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">点击上传</p>
            <p class="ant-upload-hint">支持扩展名：.mp4</p>
          </a-upload-dragger>
        </a-form-model-item>
      </template>
      <!-- 视频 end -->
      <!-- 文件 start -->
      <template v-else-if="form.type == 'file'">
        <a-form-model-item label="文件" prop="fileUrl">
          <a-upload-dragger
            name="file"
            :file-list="fileListFile"
            :multiple="false"
            action="/xapi/api/upload/create"
            :before-upload="beforeUpload"
            @change="
              (e) => {
                uploadChange(e, 'file');
              }
            "
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">点击上传</p>
            <p class="ant-upload-hint">支持扩展名：.file.status等文件</p>
          </a-upload-dragger>
        </a-form-model-item>
      </template>
      <!-- 文件 end -->

      <!-- 图文链接 start -->
      <div v-else-if="form.type == 'news'">
        <a-form-model-item
          label="链接URL"
          prop="linkUrl"
          :rules="{ required: true, message: '请填写链接地址', trigger: 'blur' }"
        >
          <a-input placeholder="请填写链接地址，例如：http://xxx.com" v-model="form.linkUrl" />
        </a-form-model-item>

        <a-form-model-item
          label="主标题"
          prop="mainTitle"
          :rules="{ required: true, message: '请填写主标题', trigger: 'blur' }"
        >
          <a-input placeholder="请填写主标题" v-model="form.mainTitle" />
        </a-form-model-item>

        <a-form-model-item
          label="副标题"
          prop="subTitle"
          :rules="{ required: true, message: '请填写副标题', trigger: 'blur' }"
        >
          <a-input placeholder="请填写副标题" v-model="form.subTitle" />
        </a-form-model-item>

        <a-form-model-item
          label="缩略图"
          prop="thumbUrl"
          :rules="{ required: true, message: '请上传缩略图', trigger: 'blur' }"
        >
          <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            action="/xapi/api/image/create"
            :before-upload="beforeUpload"
            @change="
              (e) => {
                uploadChange(e, 'news');
              }
            "
          >
            <img class="avatar" v-if="form.thumbUrl" :src="form.thumbUrl" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>
      </div>
      <!-- 图文链接 end -->

      <!-- 小程序 start -->
      <div v-else-if="form.type == 'miniprogram'">
        <a-form-model-item
          label="小程序标题"
          prop="miniTitle"
          :rules="{ required: true, message: '请填写小程序标题', trigger: 'blur' }"
        >
          <a-input placeholder="请填写小程序标题" v-model="form.miniTitle" />
        </a-form-model-item>

        <a-form-model-item
          label="小程序缩略图"
          prop="miniPicUrl"
          :rules="{ required: true, message: '请上传小程序缩略图', trigger: 'blur' }"
        >
          <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            action="/xapi/api/image/create"
            :before-upload="beforeUpload"
            @change="
              (e) => {
                uploadChange(e, 'miniprogram');
              }
            "
          >
            <img class="avatar" v-if="form.miniPicUrl" :src="form.miniPicUrl" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>

        <a-form-model-item
          label="小程序"
          prop="miniAppid"
          :rules="{ required: true, message: '请选择小程序', trigger: 'change' }"
        >
          <a-select placeholder="请选择小程序" v-model="form.miniAppid">
            <a-select-option v-for="(item, index) in miniList" :key="index" :value="item.app_id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
          label="小程序路径"
          prop="miniPagepath"
          :rules="{ required: true, message: '请填写小程序路径', trigger: 'blur' }"
        >
          <a-input
            placeholder="请填写小程序路径，如pages/index/index.html?id=1"
            v-model="form.miniPagepath"
          />
        </a-form-model-item>
      </div>
      <!-- 小程序 end -->

      <a-form-model-item label="发布时间" prop="order_time">
        <a-date-picker
          style="width: 100%"
          v-model="form.order_time"
          format="YYYY-MM-DD HH:mm:ss"
          :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
        />
      </a-form-model-item>

      <a-form-model-item label="部门或员工">
        <div class="ant-btn antd-pro--select-dept-box" @click="showStaffModel">
          <span class="antd-pro-linetext" v-if="!nameStr">
            选择部门或员工
          </span>
          <span class="antd-pro-linetext" v-else>
            {{ nameStr }}
          </span>
        </div>
      </a-form-model-item>

      <a-form-model-item label=" " :colon="false">
        <a-button type="primary" @click="onSubmit" :loading="btnLoading">
          完成
        </a-button>
        <a-button style="margin-left: 10px;" @click="resetForm">
          取 消
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <!-- 选择部门或员工 start -->
    <trees-list
      ref="staffModal"
      :visible="staffVisible"
      @ok="handleOkStaff"
      @cancel="handleCancelStaff"
      :checkDept="1"
    ></trees-list>
    <!-- 选择部门或员工 start -->
  </div>
</template>

<script>
const typeList = {
  text: '请输入话术内容',
  image: '请上传图片',
  video: '请上传视频',
  news: '图文',
  file: '请上传文件',
  miniprogram: '小程序'
};
import EmoticonModal from '@/view/workbench/modal/material/emoticonModal';
import { ListMixin } from '@/mixins/ListMixin';
import { httpAction, getAction } from '@/api/manage';
import moment from 'moment';
import { dateFormat } from '@/utils/util';
import treesList from '@/components/staff/treesList';
export default {
  name: 'MaterialAddModal',
  mixins: [ListMixin],
  components: {
    EmoticonModal,
    treesList
  },
  data() {
    return {
      loading: false,
      isAdd: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      btnLoading: false,
      typeList,
      form: {
        class_id: undefined,
        title: '',
        type: 'text',
        content: '',
        source_name: '',
        linkUrl: '',
        mainTitle: '',
        subTitle: '',
        thumbUrl: '',
        order_time: moment(this.timeFormat(0, 0, 'Y-m-d H:i:s')),
        department_id: [],
        staff_id: [],
        miniTitle: '',
        miniPicUrl: '',
        miniAppid: undefined,
        miniPagepath: ''
      },
      rules: {
        title: [{ required: true, message: '请输入话术标题', trigger: 'blur' }],
        type: [{ required: true, message: '请选择话术类型', trigger: 'blur' }],
        content: [{ required: true, message: '请输入话术内容', trigger: 'blur' }],
        order_time: [{ required: true, message: '请选择发布时间', trigger: 'change' }]
      },
      tags: ['客户昵称', '员工姓名', '员工手机'],
      smileVisible: false,
      visible: false,
      url: {
        list: 'scrm/work-state/talk-class',
        edit: 'scrm/talk/update',
        add: 'scrm/talk/create',
        userList: 'api/admin-user/index',
        details: 'scrm/talk/view',
        miniList: 'scrm/applet/index'
      },
      fileListImage: [],
      fileListVideo: [],
      fileListFile: [],
      fileListNews: [],
      oldCont: '',
      staffVisible: false, //选择部门或员工
      nameStr: '', //部门或员工文字
      userList: [], // 员工列表
      departmentList: [], // 部门列表
      customerId: 0,
      miniList: []
    };
  },
  created() {
    this.loadData();
    this.getMiniDetails();
    this.customerId = this.$store.state.auth.customer_id;
  },
  methods: {
    moment,
    add() {
      this.isAdd = true;
      this.visible = true;
      this.form = {
        class_id: undefined,
        title: '',
        type: 'text',
        content: '',
        source_name: '',
        linkUrl: '',
        mainTitle: '',
        subTitle: '',
        thumbUrl: '',
        order_time: moment(this.timeFormat(0, 0, 'Y-m-d H:i:s')),
        department_id: [],
        staff_id: [],
        miniTitle: '',
        miniPicUrl: '',
        miniAppid: undefined,
        miniPagepath: ''
      };
    },
    edit(record) {
      console.log(record, 'record');
      this.visible = true;
      this.form = {
        title: record.title,
        type: record.type,
        content: record.content,
        class_id: record.class_id,
        id: record.id,
        order_time: moment(this.timeFormat(record.order_time, record.insert_time, 'Y-m-d H:i:s'))
      };
      this.rules.content[0].message = this.typeList[record.type];
      if (record.type == 'news') {
        let data = JSON.parse(record.content);
        let obj = {
          mainTitle: data.title,
          subTitle: data.description,
          linkUrl: data.url,
          thumbUrl: data.picurl
        };
        this.form = Object.assign({}, this.form, obj);
      } else if (record.type == 'miniprogram') {
        const data = JSON.parse(record.content);
        const obj = {
          miniTitle: data.title,
          miniAppid: data.appid,
          miniPicUrl: data.picurl,
          miniPagepath: data.pagepath
        };
        this.form = Object.assign({}, this.form, obj);
      } else if (record.type == 'image') {
        this.fileListImage = [
          {
            uid: '1',
            name: record.source_name,
            status: 'done',
            url: record.content
          }
        ];
      } else if (record.type == 'file') {
        this.fileListFile = [
          {
            uid: '1',
            name: record.source_name,
            status: 'done',
            url: record.content
          }
        ];
      } else if (record.type == 'video') {
        this.fileListVideo = [
          {
            uid: '1',
            name: record.source_name,
            status: 'done',
            url: record.content
          }
        ];
      }
      this.getDetails(record.id);
    },
    // 提交表单
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) {
          return;
        }
        let data = JSON.parse(JSON.stringify(this.form));
        if (this.form.type == 'news') {
          data.content = JSON.stringify({
            title: data.mainTitle,
            description: data.subTitle,
            url: data.linkUrl,
            picurl: data.thumbUrl
          });
        } else if (this.form.type == 'miniprogram') {
          data.content = JSON.stringify({
            title: data.miniTitle,
            appid: data.miniAppid,
            picurl: data.miniPicUrl,
            pagepath: data.miniPagepath
          });
        }
        delete data.mainTitle;
        delete data.subTitle;
        delete data.linkUrl;
        delete data.thumbUrl;
        delete data.miniTitle;
        delete data.miniPicUrl;
        delete data.miniAppid;
        delete data.miniPagepath;
        data.order_time = parseInt(new Date(this.form.order_time).valueOf() / 1000);
        let url;
        let method;
        if (this.isAdd) {
          url = this.url.add;
          method = 'post';
        } else {
          url = this.url.edit;
          method = 'put';
        }
        this.btnLoading = true;
        httpAction(url, data, method)
          .then(() => {
            this.$message.success('保存成功');
            this.btnLoading = false;
            this.resetForm();
          })
          .catch(() => {
            this.btnLoading = false;
          });
      });
    },
    // 重置表单
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.visible = false;
      this.isAdd = false;
      this.$emit('ok', true);
    },
    radioChange(e) {
      if (this.form.type == 'text') {
        this.form.content = this.oldCont;
      } else if (this.form.type == 'image') {
        this.form.content = this.fileListImage.length ? this.fileListImage[0].url : '';
      } else if (this.form.type == 'video') {
        this.form.content = this.fileListVideo.length ? this.fileListVideo[0].url : '';
      } else if (this.form.type == 'file') {
        this.form.content = this.fileListFile.length ? this.fileListFile[0].url : '';
      }
      this.rules.content[0].message = this.typeList[e.target.value];
      this.$refs.ruleForm.clearValidate();
    },
    // 光标处插入文字
    async insert(myValue) {
      const myField = document.querySelector('#textarea');
      // console.log(myField.selectionStart, myValue, 'myValue')
      if (myField.selectionStart || myField.selectionStart === 0) {
        var startPos = myField.selectionStart;
        var endPos = myField.selectionEnd;
        this.form.content =
          myField.value.substring(0, startPos) +
          myValue +
          myField.value.substring(endPos, myField.value.length);
        await this.$nextTick(); // 这句是重点, 圈起来
        myField.focus();
        myField.setSelectionRange(endPos + myValue.length, endPos + myValue.length);
      } else {
        this.form.content += myValue;
      }
    },

    // 点击表情返回
    emoticonOk(e) {
      this.smileVisible = false;
      this.insert(e);
    },

    // 上传处理
    uploadChange(info, type) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });
      // 各种类型特殊处理
      if (type == 'image') {
        this.fileListImage = fileList;
      } else if (type == 'video') {
        this.fileListVideo = fileList;
      } else if (type == 'file') {
        this.fileListFile = fileList;
      }

      const status = info.file.status;
      if (status !== 'uploading') {
        if (type == 'image') {
          this.form.content = fileList.length ? fileList[0].url : '';
          this.form.source_name = fileList.length ? fileList[0].name : '';
        } else if (type == 'video') {
          this.form.content = fileList.length ? fileList[0].url : '';
          this.form.source_name = fileList.length ? fileList[0].name : '';
        } else if (type == 'file') {
          this.form.content = fileList.length ? fileList[0].url : '';
          this.form.source_name = fileList.length ? fileList[0].name : '';
        } else if (type == 'news') {
          this.form.thumbUrl = fileList.length ? fileList[0].url : '';
        } else if (type == 'miniprogram') {
          this.form.miniPicUrl = info.fileList.length ? info.fileList[0].url : '';
          console.log(this.form.miniPicUrl, 'miniPicUrl');
        }
      }
      if (status === 'done') {
        this.$message.success(`${info.file.name} 上传成功`);
      } else if (status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`);
      }
    },

    // 上传前判断
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('图片不能大于10MB!');
      }
      return isLt10M;
    },
    inputChange() {
      this.oldCont = this.form.content;
    },
    // 处理时间
    timeFormat(upload_time, insert_time, format) {
      if (!format) {
        format = 'Y-m-d H:i';
      }
      let time =
        upload_time > 0
          ? dateFormat(upload_time * 1000, format)
          : dateFormat(insert_time * 1000, format);
      return time;
    },

    // 选择部门或员工
    showStaffModel() {
      this.staffVisible = true;
    },
    // 选择员工  确认
    handleOkStaff(userIds, deptIds, name) {
      this.form.staff_id = userIds;
      this.form.department_id = deptIds;
      this.nameStr = name.toString();
      this.staffVisible = false;
    },
    // 取消
    handleCancelStaff() {
      this.staffVisible = false;
    },

    // 获取员工列表
    getUserList(department_id, staff_id) {
      getAction(this.url.userList).then((res) => {
        if (res && res.children) {
          this.queryData([res]);

          this.departmentList = this.unique(this.departmentList);
          this.userList = this.unique(this.userList);
          let arr1 = department_id.map((item) => {
            let a = this.departmentList.filter((item2) => {
              return item2.id == item;
            });
            return a[0].name;
          });
          let arr2 = staff_id.map((item) => {
            let a = this.userList.filter((item2) => {
              return item2.id == item;
            });
            return a[0].name;
          });
          let arr = arr1.concat(arr2);
          this.nameStr = arr.join(',');
        }
      });
    },

    queryData(data) {
      data.forEach((item) => {
        if (item.children) {
          this.queryData(item.children);
          this.departmentList.push({
            id: item.id,
            name: item.name
          });
        } else {
          this.userList.push(item);
        }
      });
    },

    // 数组对象根据id去重
    unique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
    },

    // 获取话术详情
    getDetails(id) {
      getAction(this.url.details, { id: id }).then((res) => {
        this.getUserList(res.department_id, res.staff_id);
        (this.form.department_id = res.department_id), (this.form.staff_id = res.staff_id);
      });
    },

    // 获取小程序列表
    getMiniDetails() {
      getAction(this.url.miniList).then((res) => {
        this.miniList = res;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  overflow: hidden;
  overflow-y: auto;
  height: calc(100vh - 480px);
  padding-top: 30px;
  &-group {
    height: calc(100vh - 280px);
  }
}
.cont {
  padding: 24px;
}
.title {
  min-height: 48px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  h3 {
    display: inline-block;
    margin: 0;
    padding: 16px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.form {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  .search {
    width: 300px;
  }
}

.icon-smile {
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    color: #13c2c2;
  }
}
.tag {
  cursor: pointer;
}

.smile-box {
  width: 542px;
}
.avatar {
  max-width: 196px;
  max-height: 196px;
}

.antd-pro--select-dept-box {
  width: 100%;
  padding: 0 10px 0 5px;
  line-height: 32px;
  white-space: normal;
}
.antd-pro-linetext {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  padding-top: 15px;
}
</style>
