<template>
    <div>
        <template v-if="!isMaterial">
            <a-form-model ref="ruleForm" :model="form" v-if="!showNext">
                <h3>
                    <a-icon type="setting" style="color:#13c2c2; margin-right: 8px;" />
                    设置欢迎语
                    <a-popover placement="top">
                        <template slot="content">
                            <div class="tips">仅可添加两条消息，一条文字消息+一条其他类型（图片，网址链接）</div>
                        </template>
                        <a-icon type="exclamation-circle" style="color: red" />
                    </a-popover>
                    <div class="welcome-tips" v-if="form.type == 3">企业欢迎语仅需建立一条即可，不能创建多条</div>
                </h3>
                <a-select v-model="form.type" @change="handleChange" :disabled="!isAdd">
                    <a-select-option value="1">
                        个人欢迎语
                    </a-select-option>
                    <a-select-option value="2">
                        部门欢迎语
                    </a-select-option>
                    <a-select-option value="3">
                        企业欢迎语
                    </a-select-option>
                </a-select>
                <a-form-model-item label="" :colon="false">
                    <template v-if="form.talk.length">
                        <!-- 企业只能添加1条 -->
                        <a-button v-if="form.type == 3" type="link" style="margin-left: -15px" :disabled="form.talk.length >= 1 ? true : false" @click="materialSelect">继续添加</a-button>
                        <!-- 个人和部门最多2条 -->
                        <a-button v-else type="link" style="margin-left: -15px" :disabled="form.talk.length >= 2 ? true : false" @click="materialSelect">继续添加</a-button>
                        <div class="tag-box">
                            <a-tag v-for="(item, index) in form.talk" :key="index" :color="item.type == 'text' ? 'cyan' : item.type == 'news' ? 'green' : 'red'" closable @close="e=> {delTag(e, index)}">{{typeList[item.type]}}-{{item.title}}</a-tag>
                        </div>
                    </template>
                    <a-button type="link" style="margin-left: -15px" v-else @click="materialSelect">在话术库选择</a-button>
                    <div class="btn-box">
                        <a-button type="primary" @click="onSubmit" :loading="btnLoading">
                            确 定
                        </a-button>
                        <template v-if="isAdd">
                            <a-button v-if="form.type == 1" style="margin-left: 10px;" @click="e => {onSubmit(e, 1)}" :loading="btnLoading">
                                保存并设置使用员工
                            </a-button>
                            <a-button v-else-if="form.type == 2" style="margin-left: 10px;" @click="e => {onSubmit(e, 2)}">
                                保存并设置使用部门
                            </a-button>
                        </template>

                        <a-button style="margin-left: 10px;" @click="cancel">
                            取 消
                        </a-button>

                        <a-button v-if="!isAdd" type="danger" style="margin-left: 10px;" @click="del(form.id)">
                            删 除
                        </a-button>
                    </div>
                </a-form-model-item>
            </a-form-model>
            <a-form-model ref="manForm" :model="manForm" v-else>
                 <!-- 员工下拉 start -->
                 <template v-if="type == 1">
                     <h3 style="margin-top: 20px">
                         <a-icon type="team" style="color:#13c2c2; margin-right: 8px;" />
                         使用员工设置
                     </h3>
                     <a-form-model-item label="添加使用员工" :rules="{ required: true, message: '请选择员工', trigger: 'change'}">
                         <a-select mode="multiple" v-model="manForm.member_id" style="width: 100%" placeholder="请选择员工" :allowClear="true" show-search :filter-option="filterOption">
                             <a-select-option v-for="item in userList" :key="item.id">
                                 <a-avatar :size="20" style="margin-right: 5px" :src="item.avatar" icon="user" />{{item.name}}
                             </a-select-option>
                         </a-select>
                         <p></p>
                         <a-button type="primary" @click="onSubmitMan" :loading="btnLoading">
                             确 定
                         </a-button>
                     </a-form-model-item>
                 </template>
                 <!-- 员工下拉 end -->

                 <!-- 部门下拉 start -->
                 <template v-else-if="type == 2">
                     <h3 style="margin-top: 20px">
                         <a-icon type="team" style="color:#13c2c2; margin-right: 8px;" />
                         使用部门设置
                     </h3>
                     <a-form-model-item label="添加使用部门" :rules="{ required: true, message: '请选择部门', trigger: 'change'}">
                         <a-tree-select allow-clear multiple v-model="manForm.member_id" style="width: 100%" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="departmentList" placeholder="请选择部门" tree-default-expand-all :replaceFields="{children:'children', title:'name', key:'key', value: 'id' }"></a-tree-select>
                         <p></p>
                         <a-button type="primary" @click="onSubmitMan" :loading="btnLoading">
                             确 定
                         </a-button>
                     </a-form-model-item>
                 </template>
             </a-form-model>
             <!-- 部门下拉 end -->
        </template>
        <template v-else>
            <alert-material-modal @ok="AlertMaterialModalOk" @back="hiddenMaterial"></alert-material-modal>
        </template>
    </div>
</template>

<script>
    const typeList = {
        text: '文本',
        image: '图片',
        video: '视频',
        news: '图文',
        file: '文件'
    };
    import { getAction, putAction, postAction, deleteAction, httpAction } from '@/api/manage'
    import AlertMaterialModal from './AlertMaterialModal.vue';
    export default {
        name: 'AddModal',
        components: {
            AlertMaterialModal
        },
        data() {
            return {
                btnLoading: false,
                labelCol: { span: 6 },
                wrapperCol: { span: 18 },
                form: {
                    type: '1',
                    talk: [],
                    talk_ids: [],
                    id: '',
                    class_id: []
                },
                typeList,
                type: '1',
                url: {
                    add: 'scrm/welcome-talk/create',
                    edit: 'scrm/welcome-talk/update',
                    delete: 'scrm/welcome-talk/delete',
                    addMan: 'scrm/welcome-talk/create-member',
                    userList: 'api/admin-user/index',
                    departmentList: 'api/admin-department/index'
                },
                manForm: {
                    member_id: [],
                    wt_id: '', // 话术id
                },
                isAdd: true,
                showNext: false,
                userList: [],
                departmentList: [],
                isMaterial: false,
            }
        },
        mounted() {},
        methods: {
            // 选择哪种欢迎语
            handleChange(e) {
                this.form.type = e;
                this.type = e;
            },
            // 编辑
            edit(record) {
                if (record.type == 'edit') {
                    this.isAdd = false;
                    let arr = [];
                    arr = record.data.talk_message.map(item => {
                        return item.class_id
                    });
                    this.form = Object.assign({}, {
                        type: record.data.type + '',
                        talk: record.data.talk_message,
                        talk_ids: record.data.talk_id.split(','),
                        id: record.data.id,
                        class_id: arr
                    })
                    this.$emit('ok', { showTab: true, showIndex: record.data.type })
                } else if (record.type == 'set') {
                    this.manForm.wt_id = record.data.id;
                    this.manForm.member_id = [];
                    this.manForm.member_id = record.data.member.map(item => {
                        return item.id
                    });
                    this.type = record.data.type;
                    if (record.data.type == 1) {
                        this.getUserList();
                    } else if (record.data.type == 2) {
                        this.getDepartmentList();
                    }
                    this.showNext = true;

                    this.$emit('ok', { showTab: false })
                }
            },
            // 提交表单
            onSubmit(e, set) {
                this.$refs.ruleForm.validate(valid => {
                    if (!valid) { return false; }
                    this.btnLoading = true;
                    let data = JSON.parse(JSON.stringify(this.form));
                    delete data.talk;

                    let url;
                    let method;
                    if (this.isAdd) {
                        url = this.url.add;
                        method = 'post'
                    } else {
                        url = this.url.edit;
                        method = 'put'
                    }
                    httpAction(url, data, method).then((res) => {
                            this.btnLoading = false;
                            this.$message.success('保存成功');
                            this.resetForm();
                            if (set) {
                                this.manForm.wt_id = res;
                                this.showNext = true;
                                if (this.type == 1) { this.getUserList(); } else if (this.type == 2) {
                                    this.getDepartmentList();
                                }
                                this.$emit('ok', { showTab: false })
                            } else {
                                this.$emit('ok')
                            }
                        })
                        .catch(e => {
                            this.btnLoading = false;
                        });
                });
            },
            // 重置表单
            resetForm(bool) {
                this.$refs.ruleForm.resetFields();
            },
            // 删除
            del(id) {
                let _this = this;
                _this.$confirm({
                    title: '确认删除？',
                    content: '请确认是否删除？',
                    okType: 'danger',
                    onOk() {
                        deleteAction(_this.url.delete, { id: id }).then((res) => {
                            _this.$message.success('删除成功');
                            _this.$emit('ok')
                        });
                    }
                });
            },
            // 取消
            cancel() {
                this.resetForm();
                this.$emit('ok')
            },
            // 删除标签
            delTag(e, index) {
                e.preventDefault();
                this.form.talk.splice(index, 1)
                this.form.talk_ids.splice(index, 1)
                this.form.class_id.splice(index, 1)
            },
            // 点击选择话术
            materialSelect() {
                this.isMaterial = true;
            },
            hiddenMaterial() {
                this.isMaterial = false;
            },
            // 选择话术确定
            AlertMaterialModalOk(e) {
                this.isMaterial = false;
                this.form.talk.push(e);
                this.form.talk_ids.push(e.id);
                this.form.class_id.push(e.class_id);
            },
            // 获取员工列表
            getUserList() {
                getAction(this.url.userList).then(res => {
                    if (res && res.children) {
                        this.queryData(res.children);
                        this.userList = this.unique(this.userList);
                    }
                });
            },
            queryData(data) {
                data.forEach(item => {
                    if (item.children) {
                        this.queryData(item.children);
                    } else {
                        this.userList.push(item);
                    }
                });
            },
            // 数组对象根据id去重
            unique(arr) {
                const res = new Map();
                return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
            },
            // 获取部门列表
            getDepartmentList() {
                getAction(this.url.departmentList).then(res => {
                    this.departmentList = res ? res : [];
                });
            },
            // 提交员工或者部门设置
            onSubmitMan() {
                this.$refs.manForm.validate(valid => {
                    if (!valid) { return false; }
                    // this.btnLoading = true;
                    let data = JSON.parse(JSON.stringify(this.manForm));
                    postAction(this.url.addMan, data).then((res) => {
                            this.btnLoading = false;
                            this.$message.success('设置成功');
                            this.resetManForm();
                            this.$emit('ok')
                        })
                        .catch(() => {
                            this.btnLoading = false;
                        });
                });
            },
            // 重置表单
            resetManForm(bool) {
                this.$refs.manForm.resetFields();
            },
            handleConfirmPass(rule, value, callback) {
                callback('asdf')
            },
            filterOption(input, option) {
                return (
                    option.componentOptions.children[1].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
        },
        watch: {
            type() {
                // 非编辑状态type切换重置表单
                if (!this.isAdd) { return; }
                this.form = {
                    type: this.type,
                    talk: [],
                    talk_ids: [],
                    class_id: []
                };
            }
        }
    }
</script>

<style lang="less" scoped>
    .cont {
        padding: 24px;
    }
    .title {
        min-height: 48px;
        padding: 0 24px;
        color: rgba(0, 0, 0, .85);
        font-weight: 500;
        font-size: 16px;
        background: #fff;
        border-bottom: 1px solid #f0f0f0;
        h3 {
            display: inline-block;
            margin: 0;
            padding: 16px 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .form {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        .search {
            width: 300px;
        }
    }
    .btn-box {
        margin-top: 20px;
    }
    .row {
        padding: 30px 0 0 30px;
    }
    .tips {
        width: 250px;
    }
    .welcome-tips {
        display: inline-block;
        color: red;
        margin-left: 30px;
        font-size: 12px;
        font-weight: 400;
    }
</style>