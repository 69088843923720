export default {
    state: {
        token: '',
        customer_id: 0,
        group_id: '',
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_CUSTOMER_ID: (state, customer_id) => {
            state.customer_id = customer_id;
        },
        SET_GROUP_ID: (state, group_id) => {
            state.group_id = group_id;
        },
        DEL_TOKEN: (state) => {
            state.token = '';
        }
    },

    actions: {
        authLogin({commit}, token) {
            commit('SET_TOKEN', token);
        },
        setCustomerId({commit}, customer_id) {
            commit('SET_CUSTOMER_ID', customer_id);
        },
        setGroupId({ commit }, group_id) {
            commit('SET_GROUP_ID', group_id);
        },
        delToken({commit}) {
            commit('DEL_TOKEN');
        },
    }
}
