<template>
    <div class="contents">
        <a-collapse v-model="activeKey" expandIconPosition="right" accordion>
            <a-collapse-panel key="1" header="话术库">
                <a-tabs :tabBarGutter="0" class="auto-tabs" v-model="tagsKey">
                    <a-tab-pane key="1" tab="全部话术" :forceRender="true">
                        <material-list-modal :title="keyword"></material-list-modal>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="我的话术" :forceRender="true">
                        <material-add-modal ref="addModalRef" @ok="materialAddOk"></material-add-modal>
                        <material-my-modal ref="myModalRef" @ok="materialMyOk" :title="keyword" v-show="!isAdd"></material-my-modal>
                    </a-tab-pane>
                    <a-button type="primary" size="small" slot="tabBarExtraContent" style="margin-right: 3px" @click="add">
                        添加话术
                    </a-button>
                    <a-input-search slot="tabBarExtraContent" placeholder="搜索内容" style="width: 100px" @search="onSearch" @change="onChange" :allowClear="true" />
                </a-tabs>
            </a-collapse-panel>
            <template v-if="customerId != 0">
                <a-collapse-panel key="5" header="欢迎语">
                    <welcomes-list></welcomes-list>
                </a-collapse-panel>
                <a-collapse-panel key="2" header="客户标签管理">
                    <tags-list-modal :isDefault="false"></tags-list-modal>
                </a-collapse-panel>
                <a-collapse-panel key="3" header="今日联系任务">
                    <task-modal></task-modal>
                </a-collapse-panel>
                <a-collapse-panel key="4" header="清除缓存">
                    <a-button type="primary" block @click="clearStorage">清除缓存</a-button>
                    <a-alert style="margin-top: 20px;" message="同一企业切换不同企业微信账号，请点击此处清除缓存" type="warning" show-icon />
                </a-collapse-panel>
            </template>
        </a-collapse>
    </div>
</template>

<script>
    import { Empty } from 'ant-design-vue';
    import MaterialListModal from './modal/material/listModal'
    import MaterialMyModal from './modal/material/myModal'
    import MaterialAddModal from './modal/material/addModal'
    import TagsListModal from './modal/tags/tagsListModal'
    import TaskModal from './modal/task/taskModal'
    import WelcomesList from './modal/welcome/welcomesList.vue';
    export default {
        name: 'workbench',
        beforeCreate() {
            this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        },
        components: {
            MaterialListModal,
            MaterialMyModal,
            MaterialAddModal,
            TagsListModal,
            TaskModal,
            WelcomesList
        },
        data() {
            return {
                activeKey: ['1'],
                tagsKey: '1',
                isAdd: false,
                description: '你还没有任何公司话术哦，请管理员到句子互动后台话术库中配置公司话术，或者你可以在我的话术中新建属于你自己的话术',
                keyword: '',
                customerId: 0, //客户id
            }
        },
        created() {
             this.customerId = this.$store.state.auth.customer_id;
        },
        mounted() {

        },
        methods: {
            onSearch(e) {
                this.keyword = e;
            },
            onChange(e) {
                this.keyword = e.data;
            },
            // 新增话术模版返回
            materialAddOk() {
                this.isAdd = false;
                this.$refs.myModalRef.reload();
            },
            // 我的话术模块返回
            materialMyOk(obj) {
                if(obj.type == 'edit') {
                    this.isAdd = true;
                    this.title = '修改话术'
                    this.$nextTick(function() {
                        this.$refs.addModalRef.edit(obj.data);
                    })
                }
                else if(obj.type == 'add') {
                    this.isAdd = true;
                    this.title = '新增话术'
                    this.$nextTick(function() {
                        this.$refs.addModalRef.add();
                    })
                }
            },
            // 清除缓存
            clearStorage() {
                localStorage.clear();
                this.$message.success('清除成功，页面将在2s后刷新');
                setTimeout(() => {
                    location.reload();
                }, 2000);
            },
            // 新增话术
            add() {
                this.tagsKey = '2';
                this.isAdd = true;
                this.title = '新增话术';
                this.$nextTick(function() {
                    this.$refs.addModalRef.add();
                })
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
    @import '../../style/less/func';
    .default-color {
        color: #13C2C2;
    }
    .contents {
        .ant-collapse-borderless {
            background: #fff;
        }
        .ant-collapse-borderless>.ant-collapse-item {
            border: 0;
            > .ant-collapse-header {
                padding: 12px 16px 12px 80px;
            }
        }
        .ant-collapse {
            > .ant-collapse-item ｛
                > .ant-collapse-header {
                    padding: 6px 16px;
                }

        }
    }

    // 不同类型消息展示
    .welcome-msg {
        color: #303133;
        background: #f0f5fd;
        margin-bottom: 20px;
        .link {
            background: #e7f4ff;
            color: #303133;
            border-radius: 10px;
            padding: 20px;
            cursor: pointer;
            &-title {
                line-height: 40px;
                font-weight: 600;
                margin-bottom: 6px;
                font-size: 24px;
                color: #000;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
            &-cont {
                display: flex;
                img {
                    width: 60px;
                    height: 60px;
                    vertical-align: top;
                    object-fit: cover;
                    flex-shrink: 0;
                    margin-right: 20px;
                }
            }
            &-text {
                font-size: 18px;
                color: #aaa;
                position: relative;
                line-height: 2em;
                max-height: 4em;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                box-orient: vertical;
                word-wrap: break-word;
                flex: 1 1;
                -webkit-box-orient: vertical;
            }
        }
    }
</style>