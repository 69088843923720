<template>
    <div class="content">
        <a-spin :spinning="loading">
            <div class="my-tags">
                <a-collapse accordion :bordered="false" @change="tagChange" v-if="tagData.length" v-model="activeKey">
                    <a-collapse-panel v-for="(item, index) in tagData" :key="index.toString()" :header="item.title">
                        <div class="tag-box-cont">
                            <template v-if="isDefault">
                                <a-checkbox-group @change="checkboxChange" name="checkboxgroup[index]" v-model="checkedList[index]" :options="item.tags" />
                            </template>
                            <template v-else>
                                <a-checkbox-group @change="e => {checkboxChange(e, index)}" :disabled="item.is_default == 1" name="checkboxgroup[index]" v-model="checkedList[index]" :options="item.tags" />
                            </template>
                        </div>
                        <a-button size="small" slot="extra" type="danger" @click.stop="delQuick(item)" ghost :loading="item.btnLoading">取消快捷操作</a-button>
                    </a-collapse-panel>
                </a-collapse>
                <div v-else>
                    <a-empty :image="simpleImage" description="暂无标签" />
                </div>
            </div>
        </a-spin>
    </div>
</template>

<script>
    import { Empty } from 'ant-design-vue';
    import { getAction, postAction, deleteAction } from '@/api/manage'
    import { ListMixin } from '@/mixins/ListMixin'
    export default {
        mixins: [ListMixin],
        beforeCreate() {
            this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        },
        props: {
            // 是否可以选择系统标签
            isDefault: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                isSend: false,
                tagData: [], //标签
                checkedList: [],    // 选中标签组
                activeKey: [],
                url: {
                    list: 'scrm/work-state/get-user-tag',
                    tagList: 'scrm/fast-operation/index', // 快捷操作标签列表

                    add: 'scrm/tag/create',
                    delete: 'scrm/tag/delete-user-tag',

                    delQuick: 'scrm/fast-operation/delete/1'
                },
                selected: [],
                tagsName: [], //所有标签名字
                corpid: '',
                wechatLogin: '',
                token: '',
                queryParam: {
                    customer_id: ''
                },
                visible: false,
                confirmLoading: false,
            }
        },
        created() {
            let quickTagKey = localStorage.getItem('quickTagKey');
            if(quickTagKey) {
                this.activeKey = quickTagKey
            }
            this.loadData();
            this.getTagList();
        },
        methods: {
            getTagList() {
                let obj = {
                    type: 1 // 1标签 2话术
                }
                getAction(this.url.tagList, obj).then((res) => {
                    if(!res.length) {this.tagData = []; return}
                    let data = res.map(item => {
                        item.btnLoading = false;
                        return item;
                    });
                    data.map(item => {
                        if (item.tags) {
                            item.tags.forEach(childItem => {
                                childItem.label = childItem.name;
                                childItem.value = childItem.id;
                                let obj = {
                                    label: childItem.name,
                                    value: childItem.id
                                }
                                this.tagsName.push(obj);
                                return childItem;
                            })
                        }
                    })
                    this.tagData = data;
                })
            },

            // 数组去重
            ArrayToHeavy(arr) {
                var result = arr.reduce(function(v, i) {
                    if (Array.isArray(i)) {
                        i.forEach(function(item) {
                            if (v.indexOf(item) < 0) {
                                v.push(item);
                            }
                        });
                    } else {
                        if (v.indexOf(i) < 0) {
                            v.push(i);
                        }
                    }
                    return v;
                }, []);
                return result;
            },

            // 删除数组内重复数
            DelSameArray(arr1, arr2) {
                arr1 = arr1.filter(item => {
                    let idList= arr2.map(v => v.id)
                    return !idList.includes(item.id)
                })
            },

            resultSameValue(arr1, arr2) {
                let newArr = Array.from(new Set([...arr1].filter(x => arr2.includes(x))));
                return newArr;
            },

            // 改变标签
            checkboxChange(e, index) {
                let url;
                let arr = [];
                let newSelected = this.resultSameValue(this.selected, this.tagData[index].tags.map(item => {return item.id}));

                let data = {};
                if(newSelected.length > e.length) {
                    // 删除
                    url = this.url.delete
                    arr = newSelected.filter(item => {
                        if (!e.includes(item)) return item;
                    });
                    data = {
                        tag_id: arr,
                        customer_id: this.queryParam.customer_id
                    }
                }
                else {
                    // 增加
                    url = this.url.add
                    arr = e.filter(item => {
                        if (!newSelected.includes(item)) return item;
                    })
                    data = {
                        tag_id: arr,
                        customer_id: [this.queryParam.customer_id]
                    }
                }
                this.loading = true;
                postAction(url, data).finally(() => {
                    this.loadData();
                });
            },

            // 取消快捷操作
            delQuick(item) {
                item.btnLoading = true;

                let obj = {
                    type: 1,    // 1标签 2话术
                    group_id: item.id
                };
                deleteAction(this.url.delQuick, obj).then(() => {
                    this.$message.success('删除成功！')
                    this.getTagList();
                })
                .finally( () => {
                    item.btnLoading = false;
                })
            },

            // 标签tab改变
            tagChange(e) {
                localStorage.setItem('quickTagKey', e);
            },
        },
        watch: {
            selected() {
                this.checkedList = [];
                this.tagData.forEach(() => {
                    this.checkedList.push(this.selected)
                })
            },
            tagData() {
                this.checkedList = [];
                this.tagData.forEach(() => {
                    this.checkedList.push(this.selected)
                })
            },
            dataSource() {
                this.selected = this.dataSource.map(item => {
                    return item.id
                })
            }
        }
    }
</script>

<style lang="less">
    // 标签弹窗
    .my-tags {
        .tag-box {
            .title {
                margin-top: 20px;
                padding: 15px 20px;
                font-weight: 700;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: #f1f1f1;
            }
            &-cont {
                padding: 20px 0;
                vertical-align: middle;
                * {
                    vertical-align: middle;
                }
                .input-w {
                    width: 150px;
                }
                .pad {
                    padding-top: 5px;
                    padding-left: 10px;
                }
            }
            .col-sp {
                display: inline-block;
                width: 440px;
                .ant-input, .ant-btn {
                    margin: 0 10px;
                }
            }
            .ant-tag {
                cursor: pointer;
            }
        }
        .ant-checkbox-group-item {
            border-radius: 10px;
            color: #9b9b9b;
            border: 2px solid #9b9b9b;
            margin-bottom: 10px;
            margin-right: 10px;
            padding: 10px 20px;
            font-size: 26px;
            line-height: 40px;
            span {
                color: #333;
            }
            .ant-checkbox {
                display: none;
            }
            &:hover {
                .ant-checkbox-inner {
                    display: none;
                    background: none;
                }
            }
        }
        .ant-checkbox-wrapper-disabled {
            border: 2px solid #eee;
            border-radius: 10px;
            span {
                color: #bbb;
            }
        }
        .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
            border: 2px solid #13c2c2 !important;
            border-radius: 10px;
            font-size: 26px;
            background: #13c2c2;
            span {
                color: #fff;
            }
            &::after {
                display: none;
            }
        }
        .ant-checkbox + span {
            padding: 0;
        }
        .ant-checkbox-inner {
            display: none;
            background: none;
            border: 0;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
            display: none;
            background: none;
            border: 0;
        }
    }
</style>
<style lang="less" scoped>
.content {
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 200px);
    .ant-collapse-borderless {
        background: #fff;
    }
    .ant-collapse-borderless>.ant-collapse-item {
        border: 0;
    }
}

.today-tips {
    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;
    }
    .item {
        border: 2px solid #eee;
        border-radius: 10px;
        margin-top: 20px;
        padding-top: 20px;
        .name {
            padding: 0 20px;
            font-size: 28px;
            line-height: 60px;
            color: #333;
        }
        .tips {
            padding: 0 20px;
            font-size: 26px;
            line-height: 40px;
            color: #999;
            word-break: break-all;
        }
        .btn {
            width: 100%;
            margin-top: 20px;
        }
    }
}
</style>
