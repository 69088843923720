import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import { ACCESS_TOKEN, CORP_ID } from '@/store/mutation-types';
import storage from 'store';
import { getAction, postAction } from '@/api/manage';
import routes from './map';
import NProgress from 'nprogress';
import '@/components/NProgress/nprogress.less';

Vue.use(Router);

NProgress.configure({ showSpinner: false }); //

const router = new Router({
  mode: 'history',
  routes: routes
});

// 路由守卫，所有路由都会经过此处
router.beforeEach((to, from, next) => {
  NProgress.start();
  // 首先判断是否登录
  if (store.state.auth.token) next();
  else {
    // 判断是否企云宝url，若是则完成用户登录
    const { query } = to;
    const url = {
      oauth: 'oauth/index',
      jsSdk: 'api/jssdk/create',
      jwt: 'api/jwt/create'
    };
    if (query.wxid && query.chat_wxid) {
      postAction(url.jwt, query).then((res) => {
        store.dispatch('authLogin', res.jwt).then(() => {
          storage.set(ACCESS_TOKEN, res.jwt);
          storage.set(CORP_ID, +res.corp_id);
          store.dispatch('setCustomerId', res.customer_id).then(() => {
            next();
          });
        });
      });
      return true;
    }

    // 判断storage是否有token
    const token = storage.get(ACCESS_TOKEN);
    const cacheCorpId = storage.get(CORP_ID);
    const corpId = to.query.corpid;
    if (token && +corpId === +cacheCorpId) {
      //此处判断用于切换不同企业需更换token
      store.dispatch('authLogin', token).then(() => {
        if (corpId) {
          getConfig(url.jsSdk, corpId, next);
        } else next();
      });
    } else {
      // 授权完成跳转回来处理数据
      const login = to.query.wechat_login;
      if (+login === 1) {
        postAction(url.jwt).then((res) => {
          store.dispatch('authLogin', res.jwt).then(() => {
            storage.set(ACCESS_TOKEN, res.jwt);
            storage.set(CORP_ID, +corpId);
            if (corpId) {
              getConfig(url.jsSdk, corpId, next);
            } else next();
          });
        });
      } else {
        // 跳转到授权页面
        const jumpUrl = `${location.origin}/xapi/${
          url.oauth
        }?corpId=${corpId}&callback=${encodeURIComponent(location.href)}`;
        window.location.href = jumpUrl;
        return false;
      }
    }
  }
});

router.afterEach(() => {
  NProgress.done(); // finish progress bar
});

function getConfig(url, corpId, callback) {
  postAction(url, { corpId: corpId }).then((res) => {
    let config = JSON.parse(res.config);
    let agentConfig = JSON.parse(res.agentConfig);
    Vue.prototype.$wx.config({
      beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: config.appId, // 必填，企业微信的corpID
      timestamp: config.timestamp, // 必填，生成签名的时间戳
      nonceStr: config.nonceStr, // 必填，生成签名的随机串
      signature: config.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
      jsApiList: config.jsApiList //你要调用的sdk接口必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
    });
    Vue.prototype.$wx.ready(function() {
      Vue.prototype.$wx.agentConfig({
        corpid: agentConfig.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: agentConfig.agentid, // 必填，企业微信的应用id （e.g. 1000247）生产环境
        timestamp: agentConfig.timestamp, // 必填，生成签名的时间戳
        nonceStr: agentConfig.nonceStr, // 必填，生成签名的随机串
        signature: agentConfig.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
        jsApiList: agentConfig.jsApiList, // 必填
        success() {
          Vue.prototype.$wx.invoke('getContext', {}, function(res) {
            if (res.err_msg == 'getContext:ok') {
              let entry = res.entry; //返回进入H5页面的入口类型，目前有normal、contact_profile、single_chat_tools、group_chat_tools、chat_attachment
              if (entry == 'group_chat_tools') {
                Vue.prototype.$wx.invoke('getCurExternalChat', {}, (res3) => {
                  if (res3.err_msg == 'getCurExternalChat:ok') {
                    let chatId = res3.chatId; //返回当前客户群的群聊ID
                    store.dispatch('setGroupId', chatId).then(() => {
                      callback();
                    });
                  } else {
                    //错误处理
                    callback();
                  }
                });
              } else {
                // 获取用户的外部联系人ID
                Vue.prototype.$wx.invoke('getCurExternalContact', {}, (res2) => {
                  if (res2.err_msg === 'getCurExternalContact:ok') {
                    getAction('scrm/customer/get-customerid-by-extid', { id: res2.userId }).then(
                      (res) => {
                        store.dispatch('setCustomerId', res).then(() => {
                          callback();
                        });
                      }
                    );
                    //返回当前外部联系人userId
                  } else {
                    //错误处理
                    callback();
                  }
                });
              }
            } else {
              //错误处理
            }
          });
        },
        fail(res) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        }
      });
    });
  });
}

export default router;
