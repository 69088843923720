<template>
    <a-spin :spinning="loading">
        <div class="contents">
            <a-tabs v-model="activeKey">
                <a-tab-pane key="1" tab="今日新增僵尸">
                </a-tab-pane>
                <a-tab-pane key="2" tab="号内总僵尸">
                </a-tab-pane>
            </a-tabs>
            <!-- 查询列表 start -->
            <div v-if="dataSource.length" class="list" @scroll="onScroll">
                <ul>
                    <li v-for="(item, index) in dataSource" :key="index">
                        <div class="img-box" v-if="item.customer">
                            <a-avatar class="avatar" :size="50" icon="user" :src="item.customer.avatar" v-if="item.customer.avatar" />
                            <div class="text">
                                <div class="title">{{item.customer.name}}</div>
                            </div>
                        </div>
                    </li>
                </ul>
                <load-more :status="loadStatus"></load-more>
            </div>
            <div v-else>
                <a-empty :image="simpleImage" description="暂无数据" />
            </div>
            <!-- 查询列表 end -->
        </div>
    </a-spin>
</template>

<script>
    import { Empty } from 'ant-design-vue';
    import { getAction } from '@/api/manage'
    import loadMore from '@/components/loadMore.vue'
    import { ListMixin } from '@/mixins/ListMixin'
    export default {
        name: 'zombie-fan',
        mixins: [ListMixin],
        components: {
            loadMore,
        },
        beforeCreate() {
            this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        },
        data() {
            return {
                loading: true,
                url: {
                    list: 'scrm/customer-user-follow/del-fans-list', // （汇总信息）
                },
                queryParam: {
                    type: '1',  // 今日1，全部2
                },
                activeKey: '1',
                isSend: false, // 防止重复获取
                loadStatus: 'loading',
            }
        },
        mounted() {
            this.getDetails();
        },
        methods: {
            getDetails() {
                if (this.isSend) { return }
                var params = this.getQueryParams(); //查询条件
                if (this.ipagination.current == 1) {
                    this.loading = true;
                    this.dataSource = [];
                }
                this.isSend = true;
                this.loadStatus = 'loading'
                getAction(this.url.list, params).then(res => {
                    console.log(res, 'res')
                    if (res.data.length >= 0) {
                        this.dataSource = this.dataSource.concat(res.data);
                        if (res.data.length < this.ipagination.pageSize) {
                            this.loadStatus = 'noMore'
                        } else {
                            this.loadStatus = 'loading'
                            this.ipagination.current++
                        }
                    }
                    else {
                        this.dataSource = res;
                        this.loadStatus = 'noMore'
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.isSend = false;
                });
            },

            onScroll(e) {
                if (this.loadStatus == 'noMore') {
                    return
                }
                if (e.target.scrollTop + e.target.clientHeight + 400 >= e.target.scrollHeight) {
                    this.getDetails()
                }
            },
        },
        watch: {
            activeKey() {
                this.queryParam.type = this.activeKey;
                this.getDetails();
            }
        }
    }
</script>

<style lang="less" scoped>
.contents {
    padding: 0 20px 20px;
}
// 列表
.list {
    height: calc(100vh - 140px);
    overflow-y: auto;
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    li {
        position: relative;
        padding: 20px 0;
        border-bottom: 2px solid #eee;
        .img-box {
            display: flex;
            align-items: center;
            .avatar {
                margin-right: 20px;
            }
            .text {
                flex: 1;
            }
            .title {
                font-size: 30px;
                color: #333;
            }
        }
    }
}
</style>