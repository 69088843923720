<template>
    <div class="box-content">
        <div class="loading" v-if="loading">
            <a-spin />
        </div>
        <div class="content" v-else>
            <a-tabs default-active-key="0" @change="callback">
                <a-tab-pane key="0">
                    <template slot="tab">
                        未联系客户（{{todo}}）
                    </template>
                    <div class="need">
                        <div class="need-top">
                            <span class="prev" @click="prev">&lt;</span>
                            <span class="time">{{queryParam.date}}</span>
                            <span class="next" @click="next">&gt;</span>
                        </div>
                        <div class="need-cont" v-load-more="loaderMore">
                            <div class="item-list" v-for="(item, index) in dataSource" :key="index">
                                <div class="item">
                                    <div class="item-top">
                                        <div class="item-l">
                                            <div class="avatar" v-if="item.avatar">
                                                <img :src="item.avatar">
                                            </div>
                                            <a-avatar icon="user" class="avatar" v-else />
                                        </div>
                                        <div class="item-r">
                                            <div class="name"><span>{{item.customer_name}}</span>
                                                <span class="wx">
                                                    <template v-if="item.type == 1"> @微信</template>
                                                    <template v-else> @企业微信</template>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item-cont">
                                        {{item.name}}
                                    </div>
                                </div>
                                <div class="btn-cont">
                                    <a-button type="primary" class="btn" @click="() => sendChat(item)">
                                        复制并联系客户
                                    </a-button>
                                </div>
                            </div>
                        </div>
                        <moreLoadBox :status="loadStatus"></moreLoadBox>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="1" force-render>
                    <template slot="tab">
                        已联系客户（{{finish}}）
                    </template>
                    <div class="need">
                        <div class="need-top">
                            <span class="prev" @click="prev">&lt;</span>
                            <span class="time">{{queryParam.date}}</span>
                            <span class="next" @click="next">&gt;</span>
                        </div>
                        <div class="need-cont" v-load-more="loaderMore">
                            <div class="item-list" v-for="(item, index) in dataSource" :key="index">
                                <div class="item">
                                    <div class="item-top">
                                        <div class="item-l">
                                            <div class="avatar" v-if="item.avatar">
                                                <img :src="item.avatar">
                                            </div>
                                            <a-avatar icon="user" class="avatar" v-else />
                                        </div>
                                        <div class="item-r">
                                            <div class="name"><span>{{item.customer_name}}</span>
                                                <span class="wx">
                                                    <span v-if="item.type == 1"> @微信</span>
                                                    <span v-else> @企业微信</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item-cont">
                                        {{item.name}}
                                    </div>
                                </div>
                                <div class="btn-cont">
                                    <a-button type="primary" class="btn" @click="() => sendChat(item)">
                                        复制并联系客户
                                    </a-button>
                                </div>
                            </div>
                        </div>
                        <moreLoadBox :status="loadStatus"></moreLoadBox>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
    import { getAction } from '@/api/manage'
    import { loadMore } from '../../plugins/mixin'
    import { ListMixin } from '@/mixins/ListMixin'
    import moreLoadBox from '../../components/moreLoadBox.vue'
    export default {
        name: 'need',
        components: {
            moreLoadBox,
        },
        mixins: [loadMore, ListMixin],
        data() {
            return {
                loading: true,
                dataSource: [], //数据
                url: {
                    jssdkUrl: 'api/jssdk/create',
                    loginUrl: 'api/jwt/create',
                    indexUrl: 'oauth/index',
                    list: 'scrm/task/index', //列表
                    concatUrl: 'scrm/customer/chat', //联系我们
                },
                queryParam: {
                    name: '',
                    status: 0,
                    date: '',
                    corpid: '',
                    'per-page': 10,
                    page: 1
                },
                pageSize: 10,
                finish: 0,
                todo: 0,
                totalPage: 0,
                isSend: false, // 防止重复获取
                loadStatus: 'loading',
            }
        },
        created() {
            this.queryParam.corpid = this.$route.query.corpid;
            this.queryParam.date = this.$route.query.date;
        },
        mounted() {
            document.title = '任务';
            this.loadData();
        },
        methods: {
            loadData() {
                if (this.isSend) { return }
                this.isSend = true;
                this.loadStatus = 'loading';
                getAction(this.url.list, this.queryParam).then((res) => {
                    if (res && res.data) {
                        this.loading = false;

                        let data = res.data.list;
                        if (data.length > 0) {
                            data.map(item => {
                                try {
                                    item.display = JSON.parse(item.display);
                                } catch (e) {
                                }
                                item.validate = JSON.parse(item.validate)[0];
                                return item;
                            })
                        }
                        this.dataSource = this.dataSource.concat(data);
                        this.todo = res.data.todo;
                        this.finish = res.data.finish;
                        this.totalPage = res.totalPage;
                        if (this.totalPage == 0) {
                            this.loadStatus = 'noMore';
                            return false;
                        }
                        if (this.totalPage > this.queryParam.page) {
                            this.loadStatus = 'loading'
                        } else {
                            this.loadStatus = 'noMore'
                        }
                        this.queryParam.page++
                    }
                }).finally(() => {
                    this.loading = false;
                    this.isSend = false;
                });
            },
            callback(e) {
                this.queryParam.status = e;
                this.dataSource = [];
                this.queryParam.page = 1;
                this.isSend = false;
                this.loadData();
            },
            async loaderMore() {
                let _this = this;
                if (this.queryParam.page > this.totalPage) {
                    this.loadStatus = 'noMore';
                    return false;
                } else {
                    setTimeout(function() {
                        document.documentElement.scrollTop = document.documentElement.scrollTop + 100;
                        //获取信息
                        _this.loadData();
                    }, 100);
                }

            },
            // 上一天
            prev() {
                this.queryParam.date = this.getDay(-1, 7200000);
                this.dataSource = [];
                this.queryParam.page = 1;
                this.isSend = false;
                this.loadData();
            },
            // 下一天
            next() {
                let nowDate = new Date();
                let nextDate = new Date(this.getDay(1, 7200000));
                if (nextDate <= nowDate) {
                    this.queryParam.date = this.getDay(1, 7200000);
                    this.dataSource = [];
                    this.queryParam.page = 1;
                    this.isSend = false;
                    this.loadData();
                }

            },
            getDay(day, hours) {
                var today = new Date(this.queryParam.date);
                var targetday = today.getTime() + 1000 * 60 * 60 * 24 * day + hours
                today.setTime(targetday)
                var tYear = today.getFullYear()
                var tMonth = today.getMonth()
                var tDate = today.getDate()
                // var getHours = today.getHours()
                tMonth = this.doHandleMonth(tMonth + 1)
                tDate = this.doHandleMonth(tDate)
                return tYear + '-' + tMonth + '-' + tDate;
            },
            doHandleMonth(month) {
                var m = month
                if (month.toString().length === 1) {
                    m = '0' + month
                }
                return m
            },
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
    @import '../../style/less/func';
    .loading {
        padding: 20px 0;
        text-align: center;
    }
    .box {
        background: #fcfcfe;
        padding: 0;
    }
    .default-color {
        color: #13C2C2;
    }
    .ant-tabs-bar {
        background: #fff;
    }
    .ant-tabs-tab-active {
        border-bottom: 1px solid #13C2C2;
    }
    .table-page-search-wrapper {
        padding: 0 10px;
    }
    .content {
        .ant-tabs-nav {
            width: 100%;
            .ant-tabs-tab {
                width: 50%;
                padding: 0;
                margin: 0;
                text-align: center;
                height: 80px;
                line-height: 80px;
                font-size: 30px;
            }
        }
    }
    .need {
        &-top {
            width: 80%;
            height: 80px;
            margin: 0 auto;
            background: #fff;
            line-height: 70px;
            .bdr(80px);
            .box-shadow(0 0 10px rgba(153, 153, 153, .5));
            margin-bottom: 30px;
            span {
                .dis(ib);
                vertical-align: top;
                font-size: 30px;
                text-align: center;
            }
            .prev, .next {
                height: 80px;
                width: 25%;
                color: #666;
                font-size: 50px;
                cursor: pointer;
            }
            .time {
                width: 50%;
                font-size: 30px;
                font-weight: 700;
                color: #666;
                line-height: 80px;
            }
        }
        &-cont {
            padding: 0 20px;
            .item-list {
                padding-bottom: 30px;
            }
            .item {
                padding: 20px;
                border: 1px solid #eee;
                background: #fff;
                .bdr(5px);
                &-top {
                    font-size: 30px;
                    .item-l {
                        .dis(ib);
                        vertical-align: top;
                        .avatar {
                            width: 120px;
                            height: 120px;
                            font-size: 50px;
                            line-height: 120px;
                            img {
                                width: 120px;
                                height: 120px;
                                .bdr(50%);
                            }
                        }
                    }
                    .item-r {
                        padding-left: 20px;
                        .dis(ib);
                        vertical-align: top;
                        .name {
                            line-height: 120px;
                            font-size: 36px;
                            span {
                                .dis(ib);
                                padding-right: 5px;
                            }
                            .wx {
                                font-size: 28px;
                                color: #5ad187;
                            }
                        }
                    }
                }
                &-cont {
                    padding-top: 10px;
                    font-size: 30px;
                    line-height: 50px;
                }
            }
        }
        .btn-cont {
            height: 80px;
            .btn {
                width: 100%;
                height: 80px;
                line-height: 80px;
                font-size: 30px;
                top: -10px;
            }
        }
    }
    .ant-cont {
        .ant-nav {
            padding: 0 0 15px;
            ul {
                font-size: 0;
                border-bottom: 1px solid #eee;
                background: #fff;
            }
            .ant-li {
                .dis(ib);
                padding: 0;
                margin: 0;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-size: 14px;
                cursor: pointer;
            }
            .ant-act {
                color: #13C2C2;
                border-bottom: 2px solid #13C2C2;
            }
        }
    }
</style>