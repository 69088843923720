<template>
    <div class="emoticon">
        <div class="emoticon-list" v-if="tabIndex == index" v-for="(item, index) in dataList" :key="index">
            <div v-for="(item2, index2) in item" :key="index2">
                 <span @click="emotClick(item2, index, index2)">{{ item2 }}</span>
            </div>
        </div>
        <div class="emoticon-page">
            <span @click="pageChange(index)" v-for="(item, index) in dataList" :key="index" :class="tabIndex == index ? 'cur' : ''"></span>
        </div>
    </div>
</template>

<script>
    import {emojiIcon} from '@/view/workbench/modal/material/emojiIcon'
    export default {
        name: 'EmoticonModal',
        data() {
            return {
                tabIndex: 0,
                dataList: emojiIcon,
            }
        },
        mounted() {},
        methods: {
            pageChange(index) {
                this.tabIndex = index
            },
            emotClick (e, index, index2) {
              this.$emit('ok', e)
            }
        }
    }
</script>

<style lang="less" scoped>
    .emoticon {
        padding-bottom: 40px;
        &-list {
            div {
                display: inline;
            }
            img {
                width: 50px;
                height: 50px;
                cursor: pointer;
                margin: 5px 5px;
            }
            span {
                font-size: 38px;
                display: inline-flex;
                width: 50px;
                height: 50px;
                cursor: pointer;
                margin: 5px 5px;
                justify-content: center;
                align-items: center;
                vertical-align: middle;
            }
        }
        &-page {
            position: absolute;
            bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            align-items: center;
            span {
                width: 20px;
                height: 20px;
                margin: 0 10px;
                border-radius: 50%;
                background-color: rgba(0,0,0,.1);
                cursor: pointer;
                &.cur, &:hover {
                    background-color: #999;
                }
            }
        }
    }
</style>