<template>
    <div class="content">
        <div class="order-search">
            <a-input-search v-model="orderNumber" placeholder="搜索订单号" :allowClear="true" @search="onSearch" />
        </div>
        <div class="result" v-if="isSearch">
            <a-spin :spinning="loading" class="center">
                <template v-if="!loading">
                    <div class="other other-buy" v-if="searchOrder">
                        <div class="other-name">
                            <span>用户：</span>
                            <div class="pic"><img :src="searchOrder.headimgurl"></div>
                            <div class="name">{{searchOrder.nickname}}</div>
                        </div>
                        <a-row>
                            <a-col>
                                <span>unionid：</span>{{searchOrder.union_id}}
                            </a-col>
                        </a-row>
                        <div class="paymentno">
                            订单编号：{{searchOrder.payment_no}}
                        </div>
                        <a-row>
                            <a-col :span="12" class="flex">
                                <span>订单名称：</span><span class="flex1 text-e1">{{searchOrder.name}}</span>
                            </a-col>
                            <a-col :span="12">
                                <span>课程ID：</span>{{searchOrder.did}}
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="12">
                                <span>订单金额：</span>{{searchOrder.money / 100}}元
                            </a-col>
                            <a-col :span="12">
                                <span>订单来源：</span>{{searchOrder.from_source_name}}
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="12" class="flex">
                                <span>海报群：</span><span class="flex1" v-if="searchOrder.poster">{{searchOrder.poster}}</span><span v-else>无</span>
                            </a-col>
                            <a-col :span="12">
                                <span>海报渠道：</span><span v-if="searchOrder.channel">{{searchOrder.channel}}</span><span v-else>无</span>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="12">
                                <span>流量来源：</span>{{searchOrder.flow}}
                            </a-col>
                            <a-col :span="12">
                                <span>使用金币：</span>{{searchOrder.point}}
                            </a-col>
                        </a-row>
                        <div class="time">
                            成单时间：{{timeFormat(searchOrder.insert_time)}}
                        </div>
                        <div>
                            备注：{{searchOrder.remark}}
                        </div>
                    </div>
                    <div v-else>
                        <a-empty :image="simpleImage" description="无数据" />
                    </div>
                </template>
            </a-spin>
        </div>
    </div>
</template>

<script>
    import { Empty } from 'ant-design-vue';
    import { getAction } from '@/api/manage'
    import { dateFormat } from '@/utils/util'
    export default {
        name: 'searchOrderModal',
        beforeCreate() {
            this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        },
        data() {
            return {
                loading: true,
                url: {
                    orderUrl: 'scrm/work-state/search-order'
                },
                isSend: false, // 防止重复获取
                orderNumber: '', //订单号
                searchOrder: '', //搜索数据
                isSearch: false,
            }
        },
        created() {},
        mounted() {},
        methods: {
            // 处理时间
            timeFormat(times) {
                return dateFormat(times * 1000, 'Y-m-d H:i')
            },
            // 订单搜索
            onSearch() {
                if (this.orderNumber != '') {
                    this.isSearch = true;
                    getAction(this.url.orderUrl, { order_no: this.orderNumber }).then((res) => {
                        if (res.data == null) {
                            this.searchOrder = '';
                        }
                        else {
                            this.searchOrder = res.data.data;
                        }
                        this.loading = false;
                    });
                } else {
                    this.isSearch = false;
                }
            }
        },
        watch: {
            orderNumber() {
                if (this.orderNumber) {
                    this.searchOrder = '';
                    this.isSearch = false;
                    this.loading = true;
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
    .ant-tabs-bar {
        margin-bottom: 0;
    }
</style>
<style lang="less" scoped>
    .order-search {
        padding: 20px;
    }
    .result {
        padding: 0 30px;
        .center {
            display: block;
            margin: 20px auto;
        }
        .other-buy {
            line-height: 60px;
            display: block;
            font-size: 16px;
            .copy {
                margin-left: 10px;
                text-decoration: underline;
                cursor: pointer;
                color: #13c2c2;
            }
        }
        .other-user {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .other-name {
            display: flex;
            vertical-align: middle;
            line-height: 60px;
            .name {
                display: inline-block;
                max-width: 240px;
                height: 60px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                vertical-align: middle;
            }
            .pic {
                display: inline-block;
                vertical-align: middle;
                padding-right: 10px;
                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                }
            }
        }
        .other-sale {
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: 50px;
        }
    }
    .result {
        height: auto;
    }
    .flex {
        display: flex;
        align-items: center;
    }
    .flex1 {
        flex: 1;
    }
    .text-e1 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
</style>