<template>
    <div class="box">
        <div class="loading" v-if="loading">
            <a-spin />
        </div>
        <template v-else>
            <a-tabs default-active-key="0" @change="callback">
                <a-tab-pane key="0">
                    <template slot="tab">
                        未联系客户（{{todo}}）
                    </template>
                    <div class="table-page-search-wrapper">
                        <a-form layout="inline">
                            <a-row>
                                <a-col :span="12">
                                    <a-form-item label="跟进时间">
                                        <a-date-picker v-model="date" :format="dateFormat" :default-value="date" @change="timeChange" />
                                    </a-form-item>
                                </a-col>
                                <a-col :span="8">
                                    <a-input-search v-model="queryParam.nickname" placeholder="搜索客户昵称" enter-button @search="onSearch" />
                                </a-col>
                            </a-row>
                        </a-form>
                        <div class="table">
                            <a-table rowKey="id" :columns="columns" :dataSource="dataSource" :pagination="ipagination" :loading="loading" @change="tableChange" :scroll="{ x: '800px' }" class="table-scroll">
                                <span slot="customer_name" slot-scope="text, record">
                                    <!-- <span class="ant-avatar" v-if="record.avatar">
                                        <img :src="record.avatar">
                                    </span> -->
                                    <a-avatar :src="record.avatar" v-if="record.avatar" />
                                    <span>
                                        {{record.customer_name}}
                                    </span>
                                </span>
                                <span slot="status" slot-scope="text, record">
                                    <a-button type="primary" class="btn" @click="() => sendChat(record)">
                                        复制并联系客户
                                    </a-button>
                                </span>
                            </a-table>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="1" force-render>
                    <template slot="tab">
                        已联系客户（{{finish}}）
                    </template>
                    <div class="table-page-search-wrapper">
                        <a-form layout="inline">
                            <a-row>
                                <a-col :span="12">
                                    <a-form-item label="跟进时间">
                                        <a-date-picker v-model="date" :format="dateFormat" :default-value="date" @change="timeChange" />
                                    </a-form-item>
                                </a-col>
                                <a-col :span="8">
                                    <a-input-search v-model="queryParam.nickname" placeholder="搜索客户昵称" enter-button @search="onSearch" />
                                </a-col>
                            </a-row>
                        </a-form>
                        <div class="table">
                            <a-table rowKey="id" :columns="columns" :dataSource="dataSource" :pagination="ipagination" :loading="loading" @change="tableChange" :scroll="{ x: '800px' }" class="table-scroll">
                                <span slot="customer_name" slot-scope="text, record">
                                    <a-avatar :src="record.avatar" v-if="record.avatar" />
                                    <span>
                                        {{record.customer_name}}
                                    </span>
                                </span>
                                <span slot="status" slot-scope="text, record">
                                    <a-button type="primary" class="btn" @click="() => sendChat(record)">
                                        复制并联系客户
                                    </a-button>
                                </span>
                            </a-table>
                        </div>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </template>
    </div>
</template>

<script>
    import moment from 'moment'
    import { getAction } from '@/api/manage'
    import { ListMixin } from '@/mixins/ListMixin'
    const columns = [{
            title: '昵称',
            dataIndex: 'customer_name',
            scopedSlots: { customRender: 'customer_name' },
        },
        {
            title: '任务名称',
            dataIndex: 'name',
        },
        {
            title: '操作',
            scopedSlots: { customRender: 'status' },
            width: '180px',
            align: 'center',
            fixed: 'right'
        }
    ];
    export default {
        name: 'need',
        mixins: [ListMixin],
        data() {
            return {
                loading: true,
                needList: [], //数据
                url: {
                    jssdkUrl: 'api/jssdk/create',
                    loginUrl: 'api/jwt/create',
                    indexUrl: 'oauth/index',
                    list: 'scrm/task/index', //列表
                    concatUrl: 'scrm/customer/chat', //联系我们
                    sendMaterialUrl: 'scrm/work-state/replace-talk'
                },
                queryParam: {
                    nickname: '',
                    status: 0,
                    date: '',
                    corpid: '',
                    'per-page': 10,
                    page: 1
                },
                date: '',
                dateFormat: 'YYYY-MM-DD',
                resData: '',
                ipagination: {
                    'per-page': 10,
                    page: 1
                },
                columns,
                finish: 0,
                todo: 0,
            }
        },
        created() {
            this.queryParam.corpid = this.$route.query.corpid;
            this.queryParam.date = this.$route.query.date;
            this.date = moment(this.queryParam.date, 'YYYY-MM-DD');
        },
        mounted() {
            document.title = '任务';
            this.loadData();
        },
        methods: {
            loadData() {
                var params = this.getQueryParams(); //查询条件
                getAction(this.url.list, params).then((res) => {
                    if (res.data) {
                        let data = res.data.list;
                        if (data.length > 0) {
                            data.map(item => {
                                try {
                                    JSON.parse(item.display);
                                    item.display = JSON.parse(item.display);


                                } catch (e) {

                                }
                                item.validate = JSON.parse(item.validate)[0];
                                return item;
                            })
                        }
                        this.dataSource = data;

                    }
                    this.loading = false;
                    this.ipagination.total = res.totalCount;
                    this.todo = res.data.todo;
                    this.finish = res.data.finish;
                })
            },
            callback(e) {
                this.dataSource = [];
                this.queryParam.status = e;
                this.queryParam.page = 1;
                this.queryParam.nickname = '';
                this.loadData();
            },
            // 日期选择
            timeChange(time, timeString) {
                this.date = time;
                this.queryParam.date = timeString;
                this.dataSource = [];
                this.loadData();
            },
            onSearch(e) {
                this.loading = true;
                this.queryParam.nickname = e;
                this.dataSource = [];
                this.loadData();
            },
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
    @import '../../style/less/func';
    .loading {
        padding: 20px 0;
        text-align: center;
    }
    .box {
        background: #fcfcfe;
        padding: 0;
    }
    .default-color {
        color: #13C2C2;
    }
    .ant-tabs-bar {
        background: #fff;
    }
    .ant-tabs-tab-active {
        border-bottom: 1px solid #13C2C2;
    }
    .table-page-search-wrapper {
        padding: 0 10px;
    }
    .nav {
        padding: 0 0 30px;
        ul {
            font-size: 0;
            border-bottom: 1px solid #eee;
            background: #fff;
        }
        li {
            .dis(ib);
            width: 50%;
            height: 80px;
            line-height: 80px;
            text-align: center;
            font-size: 30px;
            cursor: pointer;
        }
        .act {
            color: #13C2C2;
            border-bottom: 4px solid #13C2C2;
        }
    }
    .need {
        &-top {
            width: 80%;
            height: 80px;
            margin: 0 auto;
            background: #fff;
            line-height: 70px;
            .bdr(80px);
            .box-shadow(0 0 10px rgba(153, 153, 153, .5));
            margin-bottom: 30px;
            span {
                .dis(ib);
                vertical-align: top;
                font-size: 30px;
                text-align: center;
            }
            .prev, .next {
                height: 80px;
                width: 25%;
                color: #666;
                font-size: 50px;
                cursor: pointer;
            }
            .time {
                width: 50%;
                font-size: 30px;
                font-weight: 700;
                color: #666;
                line-height: 80px;
            }
        }
        &-cont {
            padding: 0 20px;
            .item-list {
                padding-bottom: 30px;
            }
            .item {
                padding: 20px;
                border: 1px solid #eee;
                background: #fff;
                .bdr(5px);
                &-top {
                    font-size: 30px;
                    .item-l {
                        .dis(ib);
                        vertical-align: top;
                    }
                    .item-r {
                        padding-left: 20px;
                        .dis(ib);
                        vertical-align: top;
                        .name {
                            line-height: 120px;
                            font-size: 36px;
                            span {
                                .dis(ib);
                                padding-right: 5px;
                            }
                            .wx {
                                font-size: 28px;
                                color: #5ad187;
                            }
                        }
                    }
                }
                &-cont {
                    padding-top: 10px;
                    font-size: 30px;
                    line-height: 50px;
                }
            }
        }
        .btn-cont {
            height: 80px;
            .btn {
                width: 100%;
                height: 80px;
                line-height: 80px;
                font-size: 30px;
                top: -10px;
            }
        }
    }

    .more-cont {
        .h(140);
    }
    .more {
        // .pds(b, 72);
        .h(140);
        .lh(80);
        .pd(30, 0);
        text-align: center;
        .fz(32);
        color: #62bb9d;
        .icon-loading {
            .pos(r);
            .fz(82);
            color: #a2e2cd;
            .dis(ib);
            .w(82);
            .h(82);
            &:before {
                .pos(a);
                top: 0;
                left: 0;
                -webkit-animation: rotate 2s linear 0s infinite both;
                animation: rotate 2s linear 0s infinite both;
            }
        }
        .no-cont {
            color: #999;
        }

    }
    .goto-more {
        text-align: center;
        color: #62bb9d;
        .lh(50);
        .h(140);
        .pd(20, 0);
        .icon-jiantou {
            .fz(60);

        }
        .more-text {
            .fz(32);
        }
    }
    .ant-cont {
        .ant-nav {
            padding: 0 0 15px;
            ul {
                font-size: 0;
                border-bottom: 1px solid #eee;
                background: #fff;
            }
            .ant-li {
                .dis(ib);
                padding: 0;
                margin: 0;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-size: 14px;
                cursor: pointer;
            }
            .ant-act {
                color: #13C2C2;
                border-bottom: 2px solid #13C2C2;
            }
        }
    }
    .table-scroll .ant-table-scroll .ant-table-body {
        overflow-x: auto !important;
    }
</style>