import workbench from '@/view/workbench/index'
import custom from '@/view/custom/index'
import business from '@/view/business/index'
import needIndex from '@/view/need/need'
import needPc from '@/view/need/needPc'
import quick from '@/view/quick/index'
import analysis from '@/view/analysis/zombie-fan'
import message from '@/view/custom/sendMessage'
import {isMobile} from "../plugins/ismobile";

export default [
    {
        path: '/custom',
        name: 'custom',
        component: custom
    },
    {
        path: '/workbench',
        name: 'workbench',
        component: workbench
    },
    {
        path: '/business',
        name: 'business',
        component: business
    },
    {
        path: '/need',
        name: 'need',
        component: isMobile() ? needIndex : needPc
    },
    {
        path: '/quick',
        name: 'quick',
        component: quick
    },
    {
        path: '/analysis',
        name: 'analysis',
        component: analysis
    },
    {
        path: '/message',
        name: 'message',
        component: message
    }
]
