<template>
    <div class="contents custmer-collapse">
        <a-collapse v-model="activeKey" expandIconPosition="right" accordion @change="tabChange">
            <a-collapse-panel key="1" header="客户标签管理" v-if="customerId != 0">
                <tags-list-modal :isDefault="false"></tags-list-modal>
            </a-collapse-panel>
            <a-collapse-panel key="2" header="话术库">
                <material-list-modal></material-list-modal>
            </a-collapse-panel>
        </a-collapse>
    </div>
</template>

<script>
    import MaterialListModal from './modal/material/listModal'
    import TagsListModal from './modal/tags/tagsListModal'
    export default {
        name: 'quick',
        components: {
            MaterialListModal,
            TagsListModal,
        },
        data() {
            return {
                activeKey: [],
                customerId: 0, //客户id
            }
        },
        created() {
            let quickKey = localStorage.getItem('quickKey');
            if(quickKey) {
                this.activeKey = quickKey
            }
            this.customerId = this.$store.state.auth.customer_id;
        },
        mounted() {},
        methods: {
            // 新增话术模版返回
            materialAddOk() {
                this.isAdd = false;
            },

            tabChange(e) {
                localStorage.setItem('quickKey', e);
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
    @import '../../style/less/func';
    .default-color {
        color: #13C2C2;
    }
    .contents {
        .ant-collapse-content-box {
            padding: 0;
        }
        .ant-collapse-borderless {
            background: #fff;
        }
        .ant-collapse-borderless>.ant-collapse-item {
            border: 0;
            > .ant-collapse-header {
                padding: 12px 16px 12px 80px;
            }
        }
        .ant-collapse {
            > .ant-collapse-item ｛
                > .ant-collapse-header {
                    padding: 6px 16px;
                }

        }
    }

    // 不同类型消息展示
    .welcome-msg {
        color: #303133;
        background: #f0f5fd;
        margin-bottom: 20px;
        .link {
            background: #e7f4ff;
            color: #303133;
            border-radius: 10px;
            padding: 20px;
            cursor: pointer;
            &-title {
                line-height: 40px;
                font-weight: 600;
                margin-bottom: 6px;
                font-size: 24px;
                color: #000;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
            &-cont {
                display: flex;
                img {
                    width: 60px;
                    height: 60px;
                    vertical-align: top;
                    object-fit: cover;
                    flex-shrink: 0;
                    margin-right: 20px;
                }
            }
            &-text {
                font-size: 16px;
                color: #aaa;
                position: relative;
                line-height: 2em;
                max-height: 4em;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                box-orient: vertical;
                word-wrap: break-word;
                flex: 1 1;
                -webkit-box-orient: vertical;
            }
        }
    }
</style>

<style lang="less">
.custmer-collapse {
    > .ant-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
    }
}
</style>