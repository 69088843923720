<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  name: 'App',
  data() {
    return {
      locale: zhCN
    };
  }
};
</script>

<style lang="less">
/*控制整个滚动条*/
*::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}
*::-webkit-scrollbar:horizontal {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar {
  width: 0;
  height: 0px;
  display: none;
}

::-webkit-scrollbar:horizontal {
  width: 0;
  height: 0px;
  display: none;
}

// 商品列表
.goods-list {
  height: calc(100vh - 400px);
  overflow-y: auto;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  li {
    position: relative;
    padding: 20px 0;
    border-bottom: 2px solid #eee;
    &.pointer {
      cursor: pointer;
    }
    .process {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 5;
      width: 100%;
      height: 4px;
      background: #eee;
      span {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: #fa8c16;
        &.suc {
          background: #87d068;
        }
      }
    }
    .img-box {
      display: flex;
      align-items: center;
      .icon {
        margin-right: 20px;
        font-size: 40px;
        color: #aaa;
      }
      img {
        width: 120px;
        height: 80px;
        margin-right: 20px;
        object-fit: cover;
      }
      .text {
        flex: 1;
      }
      .title {
        font-weight: 700;
        font-size: 30px;
        color: #333;
      }
      .desc {
        font-size: 28px;
      }
      .price {
        font-size: 40px;
        font-weight: 700;
        color: #ff4d4f;
        span {
          font-size: 24px;
        }
      }
    }
    .other {
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #666;
    }
  }
}

// 自定义tab
.auto-tabs {
  .ant-tabs-nav {
    .ant-tabs-tab {
      padding: 24px 10px;
    }
  }
}

// 不同类型消息展示
.welcome-msg {
  color: #303133;
  background: #f0f5fd;
  margin-bottom: 20px;
  .link {
    background: #e7f4ff;
    color: #303133;
    border-radius: 10px;
    padding: 20px;
    cursor: pointer;
    &-title {
      line-height: 40px;
      font-weight: 600;
      margin-bottom: 6px;
      font-size: 30px;
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    &-cont {
      display: flex;
      img {
        width: 80px;
        height: 80px;
        vertical-align: top;
        object-fit: cover;
        flex-shrink: 0;
        margin-right: 20px;
      }

      &-mini {
        flex-direction: column;
        img {
          width: 134px;
          height: 100px;
          margin: 0;
        }
      }
    }
    &-text {
      font-size: 28px;
      color: #aaa;
      position: relative;
      line-height: 1.5em;
      max-height: 4em;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      box-orient: vertical;
      word-wrap: break-word;
      flex: 1 1;
      -webkit-box-orient: vertical;
    }
  }
}
.msg-item {
  position: relative;
  .title {
    line-height: 30px;
    margin-bottom: 10px;
  }
  .btn-box {
    position: absolute;
    top: 15px;
    right: 20px;
    .icon {
      margin-left: 20px;
      cursor: pointer;
      &:hover {
        color: #36cfc9;
      }
    }
  }
}
</style>
