<template>
    <a-spin :spinning="loading">
        <div class="task-box" v-if="dataSource.length" @scroll="onScroll">
            <div class="item" v-for="(item, index) in dataSource" :key="index">
                <div class="info-box">
                    <img :src="item.avatar" class="avatar">
                    <div class="info">
                        <div class="name">
                            {{item.customer_name}}
                            <span class="wx">
                                <template v-if="item.type == 1"> @微信</template>
                                <template v-else> @企业微信</template>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="tips">{{item.name}}</div>
                <a-button class="btn" type="primary" @click="concat(item)" v-if="queryParam.customer_id == item.customerid">联系客户</a-button>
                <a-button class="btn" type="primary" @click="concat(item, 'copy')" v-else>复制并联系客户</a-button>
            </div>
            <load-more :status="loadStatus"></load-more>
        </div>
        <p v-else>太棒了，任务都完成啦！</p>
    </a-spin>
</template>

<script>
    import { Empty } from 'ant-design-vue';
    import { getAction } from '@/api/manage'
    import { ListMixin } from '@/mixins/ListMixin'
    import loadMore from '@/components/loadMore.vue'
    export default {
        mixins: [ListMixin],
        components: {
            loadMore,
        },
        beforeCreate() {
            this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        },
        data() {
            return {
                url: {
                    list: 'scrm/task/index', //列表
                },
                queryParam: {
                    customer_id: '',
                    status: 0,
                },
                totalPage: 1
            }
        },
        created() {
            this.loadData()
        },
        methods: {
            loadData() {
                if(this.isSend) {return}
                var params = this.getQueryParams(); //查询条件
                if(this.ipagination.current == 1) {
                    this.loading = true;
                    this.dataSource = [];
                }
                this.isSend = true;
                this.loadStatus = 'loading'
                getAction(this.url.list, params).then((res) => {
                    this.totalPage = res.totalPage;
                    if (res.data && res.data.list.length >= 0) {
                        this.dataSource = this.dataSource.concat(res.data.list);
                        if (this.ipagination.current >= this.totalPage) {
                            this.loadStatus = 'noMore'
                        }
                        else {
                            this.loadStatus = 'loading'
                            this.ipagination.current++
                        }
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.isSend = false;
                });
            },

            onScroll(e) {
                if(this.loadStatus == 'noMore') {
                    return
                }
                if(e.target.scrollTop + e.target.clientHeight + 400 >= e.target.scrollHeight) {
                    this.loadData()
                }
            }
        },
    }
</script>

<style lang="less" scoped>
.task-box {
height: calc(100vh - 400px);
overflow-y: auto;
    .item {
        border: 2px solid #eee;
        border-radius: 10px;
        margin-bottom: 20px;
        .info-box {
            display: flex;
            align-items: center;
            padding: 20px;
            .avatar {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                margin-right: 20px;
            }
            .name {
                font-size: 28px;
                line-height: 60px;
                color: #333;
            }
            .wx {
                font-size: 24px;
                color: #5ad187;
            }
        }
        .tips {
            padding: 0 20px;
            font-size: 26px;
            line-height: 40px;
            color: #999;
            word-break: break-all;
        }
        .btn {
            width: 100%;
            margin-top: 20px;
        }
    }
}
</style>
