<template>
  <a-spin :spinning="loading">
    <div class="content">
      <div v-if="dataSource.length" class="goods-list" @scroll="onScroll">
        <ul>
          <li v-for="(item, index) in dataSource" :key="index" class="item">
            <div class="img-box">
              <img :src="item.cover" />
              <div class="text">
                <div class="title">{{ item.name }}</div>
                <div class="cont">
                  <div class="cont-price">实付：{{ item.money / 100 }}元</div>
                  <!-- 组合课 -->
                  <a-button class="btn-gray" @click="sendTips()" v-if="item.consist_id > 0">
                    发送课程
                  </a-button>
                  <!-- 训练营 -->
                  <a-button type="primary" @click="sendCourse(item)" v-else-if="item.type !== '10'">
                    发送课程
                  </a-button>
                </div>
              </div>
            </div>
            <div class="other other-buy">
              <div class="paymentno">
                订单编号：{{ item.payment_no }}
                <span class="copy" @click="onCopy(item.payment_no)">复制</span>
              </div>
              <div class="other-user">
                <div class="time">支付时间：{{ timeFormat(item.pay_time) }}</div>
                <div class="other-name" v-if="item.userInfo">
                  <div class="pic"><img :src="item.userInfo.headimgurl" /></div>
                  <div class="name">{{ item.userInfo.source_nickname }}</div>
                </div>
              </div>
              <div class="other-sale">
                <div class="other-sale-l">销售渠道：{{ item.channel }}</div>
                <div class="other-sale-r">销售老师：{{ item.sale }}</div>
              </div>
              <div class="other-sale" v-if="item.waybill_id">
                <div class="other-sale-l">物流编号：{{ item.waybill_id }}<span class="copy" @click="onCopy(item.waybill_id)">复制</span></div>
                <div class="other-sale-r">物流状态：{{ item.status ? '已收货' : '已发货' }}</div>
              </div>
            </div>

            <!-- 训练营 -->
            <div class="btn-more" v-if="item.type === '10'">
              <a-button type="primary" size="small" @click="getCourse(item)">
                {{ item.open ? '收起' : '展开' }}课程列表
                <a-icon :type="item.open ? 'up-circle' : 'down-circle'" />
              </a-button>

              <!-- 课程列表 -->
              <a-spin :spinning="item.loading">
                <div v-if="item.loading" style="height: 60px"></div>
              </a-spin>
              <div class="more-box" v-if="item.open">
                <template v-if="item.courses_detail.length > 0">
                  <div class="item" v-for="item2 in item.courses_detail" :key="item2.id">
                    <div class="title">{{ item2.id }} | {{ item2.name }}</div>
                    <!-- 训练营群课 -->
                    <a-button class="btn" size="small" type="primary" @click="sendCourse(item2)">
                      发送课程
                    </a-button>
                  </div>
                </template>
                <div v-else>
                  <a-empty :image="simpleImage" description="暂无课程" />
                </div>
              </div>
            </div>
          </li>
        </ul>
        <load-more :status="loadStatus"></load-more>
      </div>
      <div v-else>
        <a-empty :image="simpleImage" description="暂无课程" />
      </div>
    </div>
  </a-spin>
</template>

<script>
import { Empty } from 'ant-design-vue';
import { getAction } from '@/api/manage';
import { ListMixin } from '@/mixins/ListMixin';
import { dateFormat } from '@/utils/util';
import loadMore from '@/components/loadMore.vue';
export default {
  name: 'BuyListModal',
  props: ['title'],
  mixins: [ListMixin],
  components: {
    loadMore
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  data() {
    return {
      loading: true,
      url: {
        list: 'scrm/work-state/course-order',
        orderUrl: 'scrm/work-state/search-order',
        courseList: 'scrm/work-state/camp-period'
      },
      queryParam: {
        title: '',
        customer_id: ''
      },
      isSend: false, // 防止重复获取
      loadStatus: 'loading'
    };
  },
  created() {
    this.getDetails();
  },
  mounted() {},
  methods: {
    // 处理时间
    timeFormat(times) {
      return dateFormat(times * 1000, 'Y-m-d H:i');
    },

    getDetails() {
      if (this.isSend) {
        return;
      }
      var params = this.getQueryParams(); //查询条件
      if (this.ipagination.current == 1) {
        this.loading = true;
        this.dataSource = [];
      }
      this.isSend = true;
      this.loadStatus = 'loading';
      getAction(this.url.list, params)
        .then((res) => {
          if (res.data && res.data.list.length >= 0) {
            const arr = res.data.list.map((item) => {
              item.open = false;
              item.loading = false;
              item.courses_detail = [];
              return item;
            });
            this.dataSource = this.dataSource.concat(arr);
            if (res.data.list.length < this.ipagination.pageSize) {
              this.loadStatus = 'noMore';
            } else {
              this.loadStatus = 'loading';
              this.ipagination.current++;
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.isSend = false;
        });
    },

    onScroll(e) {
      if (this.loadStatus == 'noMore') {
        return;
      }
      if (e.target.scrollTop + e.target.clientHeight + 400 >= e.target.scrollHeight) {
        this.getDetails();
      }
    },
    // 复制
    onCopy(certB64) {
      let oInput = document.createElement('input');
      oInput.value = certB64;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand('Copy'); // 执行浏览器复制命令
      this.$message.success('复制成功');
      oInput.remove();
    },

    // 获取训练营课程
    getCourse(item) {
      if (item.open) {
        item.open = false;
        return;
      }
      if (this.isSend) {
        return;
      }
      if (item.courses_detail.length) {
        item.open = true;
        return;
      }
      item.loading = true;
      this.isSend = true;
      getAction(this.url.courseList, { id: item.did, courses: 1 })
        .then((res) => {
          item.open = true;
          item.courses_detail = res.data.courses_detail;
        })
        .finally(() => {
          item.loading = false;
          this.isSend = false;
        });
    },

    // 组合课提示
    sendTips(msg) {
      this.$message.error(msg || '该课程为组合课，无法发送课程。');
    }
  },
  watch: {
    title() {
      this.queryParam.title = this.title;
      this.ipagination.current = 1;
      this.getDetails();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.ant-tabs-bar {
  margin-bottom: 0;
}
</style>
<style lang="less" scoped>
// @import '@/style/less/func';
.default-color {
  color: #13c2c2;
}

.goods-list {
  li {
    padding-top: 25px;
    .cont {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .other-buy {
      padding-top: 20px;
      line-height: 50px;
      display: block;
      .copy {
        margin-left: 10px;
        text-decoration: underline;
        cursor: pointer;
        color: #13c2c2;
      }
    }
    .other-user {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .other-name {
      // display: flex;
      .name {
        display: inline-block;
        max-width: 240px;
        height: 40px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      .pic {
        display: inline-block;
        vertical-align: middle;
        padding-right: 10px;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }
    }
    .other-sale {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 50px;
    }
  }
}

.btn-more {
  padding-top: 10px;
  text-align: center;
}

.more-box {
  position: relative;
  padding: 0 20px;
  margin-top: 30px;
  background: #f3f3f3;

  &:before {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    font-size: 0;
    border-width: 20px;
    border-color: transparent transparent #f3f3f3 transparent;
    border-style: dashed dashed solid dashed;
    border-top: 0;
    transform: translateX(-50%);
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid #fff;

    &:last-child {
      border: none;
    }
    .title {
      flex: 1;
      text-align: left;
      font-weight: 700;
      line-height: 1.5;
    }

    .btn {
      margin-left: 20px;
    }
  }
}

.btn-gray {
  background: #f3f3f3;
}
</style>
