<template>
    <div class="content">
        <div v-if="showAdd">
            <div class="back-box">
                <div class="back" @click="back">
                    <a-icon type="arrow-left" /> 返回欢迎语管理
                </div>
            </div>
            <add-modal ref="addModal" @ok="addModalOk"></add-modal>
        </div>
        <div v-else>
            <words-list-modal @ok="tableModalOk"></words-list-modal>
        </div>
    </div>
</template>

<script>
    import AddModal from './modal/addModal';
    import WordsListModal from './modal/wordsListModal'
    export default {
        name: 'welcome',
        components: {
            WordsListModal,
            AddModal,
        },

        data() {
            return {
                showAdd: false,
                key: '1',
                showTab: true,
                tabIndex: ''
            }
        },
        methods: {
            tableModalOk(e) {
                this.showAdd = true;
                if (e) {
                    this.$nextTick(() => {
                        this.$refs.addModal.edit(e);
                    });
                }
            },
            // 返回
            back() {
                this.showAdd = false;
                this.showTab = true
                this.tabIndex = ''
                this.key = '1'
            },
            // tab切换
            callback(e) {
                this.key = e;
                this.$refs.addModal.type = this.key
            },
            // 新增模块返回
            addModalOk(e) {
                if (e) {
                    this.showTab = e.showTab;
                    if (e.showIndex) {
                        this.key = e.showIndex.toString();
                        this.tabIndex = e.showIndex
                    }
                } else {
                    this.back();
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .content {
        background: #fff;
    }
    .pd24 {
        padding: 24px;
    }
    .back {
        display: inline-block;
        color: #4c4c4c;
        font-weight: 300;
        padding: 10px 20px 10px 0;
        cursor: pointer;
        &:hover {
            color: #13c2c2;
        }
    }
</style>
