import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { dateFormat } from '@/utils/util'
moment.locale('zh-cn')

Vue.filter('NumberFormat', function(value) {
    if (!value) {
        return '0'
    }
    const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
    return intPartFormat
})

Vue.filter('dayjs', function(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
    return moment(dataStr).format(pattern)
})

Vue.filter('moment', function(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
    return moment(dataStr).format(pattern)
})

// 时间
Vue.filter('dateFormat', function(time, formats = 'Y-m-d') {
    let date;
    if(time) {
        time = time * 1000
        date = dateFormat(new Date(time), formats)
    }

    return date
})
