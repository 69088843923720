<template>
    <div class="contents">
        <search-order-modal></search-order-modal>
        <a-collapse v-model="activeKey" expandIconPosition="right" accordion>
            <a-collapse-panel key="0" header="网校">
                <div class="union-id" v-if="customerUnionId">
                  用户unionId： {{ customerUnionId }}
                  <span class="copy" @click="onCopy(customerUnionId)">复制</span>
                </div>
                <a-tabs :tabBarGutter="0" class="auto-tabs">
                    <template v-if="customerId != 0">
                        <a-tab-pane key="1" tab="购课记录" :forceRender="true">
                            <buy-list-modal :title="keywordCourse"></buy-list-modal>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="上课记录" :forceRender="true">
                            <study-list-modal :title="keywordCourse"></study-list-modal>
                        </a-tab-pane>
                    </template>
                    <a-tab-pane key="3" tab="课程列表" :forceRender="true">
                        <course-list-modal :title="keywordCourse" v-show="!isChapter" @ok="courseListModalOk"></course-list-modal>
                        <chapter-list-modal ref="chapterListModal" @ok="chapterListModalOk"></chapter-list-modal>
                    </a-tab-pane>
                    <a-input-search slot="tabBarExtraContent" placeholder="搜索内容" style="width: 120px" :allowClear="true" @search="onSearchCourse" />
                </a-tabs>
            </a-collapse-panel>
            <a-collapse-panel key="1" header="训练营">
                <train-list-modal></train-list-modal>
            </a-collapse-panel>
            <a-collapse-panel key="2" header="海报">
                <poster-list-modal></poster-list-modal>
            </a-collapse-panel>
            <a-collapse-panel key="3" header="电商">
                <a-tabs :tabBarGutter="0" class="auto-tabs">
                    <a-tab-pane key="1" tab="商品列表" :forceRender="true">
                        <goods-list-modal :title="keyword"></goods-list-modal>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="购物记录" :forceRender="true" v-if="customerId != 0">
                        <record-list-modal :title="keyword"></record-list-modal>
                    </a-tab-pane>
                    <a-input-search v-model="keyword" slot="tabBarExtraContent" placeholder="搜索内容" style="width: 120px" :allowClear="true" @search="onSearch" />
                </a-tabs>
            </a-collapse-panel>
        </a-collapse>
    </div>
</template>

<script>
    import GoodsListModal from './modal/goodsListModal'
    import RecordListModal from './modal/recordListModal'
    import CourseListModal from './modal/courseListModal'
    import ChapterListModal from './modal/chapterListModal'
    import StudyListModal from './modal/studyListModal'
    import BuyListModal from './modal/buyListModal'
    import SearchOrderModal from './modal/searchOrderModal.vue'
    import PosterListModal from './modal/posterListModal.vue'
    import TrainListModal from './modal/trainListModal.vue'
    import { copyText } from '../../utils/util';

    export default {
        name: 'workbench',
        components: {
            GoodsListModal,
            RecordListModal,
            CourseListModal,
            StudyListModal,
            BuyListModal,
            ChapterListModal,
            SearchOrderModal,
            PosterListModal,
            TrainListModal
        },
        data() {
            return {
                activeKey: ['0'],
                keyword: '',
                keywordCourse: '',
                isChapter: false,
                customerId: 0, //客户id
                customerUnionId: null,
            }
        },
        created() {
            this.customerId = this.$store.state.auth.customer_id;
            this.customerUnionId = this.$route.query.wx_unionid;
        },
        mounted() {

        },
        methods: {
            // 搜索
            onSearch(e) {
                this.keyword = e;
            },
            onSearchCourse(e) {
                this.keywordCourse = e;
            },

            // 课程模版确定
            courseListModalOk(e) {
                this.isChapter = true;
                this.$refs.chapterListModal.show(e);
            },

            // 章节模版确定
            chapterListModalOk() {
                this.isChapter = false;
            },
            onCopy(content) {
              copyText(content);
              this.$message.success('复制成功')
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
    @import '../../style/less/func';
    .default-color {
        color: #13C2C2;
    }
    .union-id {
      padding: 0 10px;
      font-size: 18px;

      span {
        color: green;
      }
    }

</style>
